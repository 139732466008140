import { calcCharges, roundMoney, getTaxRate, getDiscountedPrice } from '../utils/calcCharges'

export const calculatePricingDetails = state => {
  const pricingDetails = []

  state.inventoryCart.cart.forEach(({ inventoryId, quantity }) => {
    if (quantity === 0) {
      return
    }

    const {
      price,
      serviceChargePercentage,
      shouldChargeTax,
      taxPercentage,
      gratuityPercentage,
      gratuityType,
      additionalFee,
      additionalFeeTaxPercentage,
      shouldChargeAdditionalFee,
    } = getInventoryChargeInfo(state, inventoryId)
    const chargeDetails = calcCharges(
      quantity,
      price,
      serviceChargePercentage,
      taxPercentage,
      gratuityPercentage,
      additionalFee,
      additionalFeeTaxPercentage,
      shouldChargeAdditionalFee
    )

    pricingDetails.push({
      inventoryId,
      basePrice: chargeDetails.charge_base_amount,
      tax: chargeDetails.charge_tax_amount,
      taxPercent: taxPercentage,
      chargeTax: shouldChargeTax,
      serviceCharge: chargeDetails.charge_service_charge_amount,
      serviceChargePercent: serviceChargePercentage,
      additionalFeeAmount: chargeDetails.charge_additional_amount,
      additionalFeeTaxAmount: chargeDetails.charge_additional_fee_tax_amount,
      additionalFeeTaxPercent: additionalFeeTaxPercentage,
      requiredTip: gratuityType === 'CUSTOMER_VARIABLE' ? 0 : chargeDetails.charge_gratuity_amount,
      customTip: gratuityType === 'CUSTOMER_VARIABLE' ? chargeDetails.charge_gratuity_amount : 0,
      tipPercent: gratuityPercentage,
      subTotal: chargeDetails.charge_subtotal,
      total: chargeDetails.charge_amount,
    })
  })

  return pricingDetails
}

export const getInventoryChargeInfo = (state, inventoryId) => {
  const selectedInventoryItem = state.inventoryCart.cart.find(item => item.inventoryId === inventoryId)
  const selectedInventory = state.entities.inventory.get(inventoryId)
  const price = selectedInventory.get('price')
  const applyServiceCharge = selectedInventory.get('applyServiceCharge')
  const serviceChargeType = selectedInventory.get('serviceChargeType')
  const serviceChargePercentage = applyServiceCharge
    ? {
        DEFAULT_SERVICE_CHARGE: state.venueInfo.venueDefaultServiceCharge,
        SPECIFIC_SERVICE_CHARGE: selectedInventory.get('serviceChargeAmount'),
      }[serviceChargeType]
    : 0
  const chargeGratuity = selectedInventory.get('chargeGratuity')
  const gratuityAmountType = selectedInventory.get('gratuityAmountType')
  const gratuityPercentage = chargeGratuity
    ? {
        DEFAULT_FIXED: state.venueInfo.venueDefaultGratuity,
        CUSTOM_FIXED: selectedInventory.get('gratuityAmount'),
        CUSTOMER_VARIABLE: state.formFields.get('customTip') || 0,
      }[gratuityAmountType]
    : 0
  const chargeTax = selectedInventory.get('chargeTax')
  const { taxGroups } = state.venueInfo
  const taxGroupId = selectedInventory.get('taxGroupId')
  const taxPercentage = chargeTax ? getTaxRate(taxGroups, taxGroupId) : 0
  const additionalFee = selectedInventory.get('additionalFee')
  const chargeAdditionalFeeTax = selectedInventory.get('chargeAdditionalFeeTax')
  const additionalFeeTaxId = selectedInventory.get('additionalFeeTaxId')
  const additionalFeeTaxPercentage = chargeAdditionalFeeTax ? getTaxRate(taxGroups, additionalFeeTaxId) : 0

  return {
    price,
    quantity: selectedInventoryItem.quantity,
    serviceChargePercentage,
    shouldChargeTax: chargeTax,
    taxPercentage,
    gratuityPercentage,
    gratuityType: gratuityAmountType,
    additionalFee,
    shouldChargeAdditionalFee: selectedInventory.get('chargeAdditionalFee'),
    additionalFeeTaxPercentage,
    entryPerReservation: selectedInventory.get('entryPerReservation'),
  }
}

export function calculateSelectedInventoryPrices(state) {
  const selectedInventoryCart = getSelectedInventoryCart(state)

  const {
    basePrice,
    serviceCharge,
    tax,
    requiredTip,
    customTip,
    tip,
    subTotal,
    additionalFeeAmount,
    additionalFeeTaxAmount,
    total,
    promoCodeEligibleAmount,
  } = selectedInventoryCart.reduce(
    (
      acc,
      { inventoryId, basePrice, serviceCharge, tax, requiredTip, customTip, subTotal, additionalFeeAmount, additionalFeeTaxAmount, total }
    ) => {
      const selectedInventory = state.entities.inventory.get(inventoryId)
      return {
        basePrice: acc.basePrice + basePrice,
        serviceCharge: acc.serviceCharge + serviceCharge,
        tax: acc.tax + tax,
        requiredTip: acc.requiredTip + requiredTip,
        customTip: acc.customTip + customTip,
        tip: acc.tip + (requiredTip || customTip),
        subTotal: acc.subTotal + parseFloat(subTotal),
        additionalFeeAmount: acc.additionalFeeAmount + additionalFeeAmount,
        additionalFeeTaxAmount: acc.additionalFeeTaxAmount + additionalFeeTaxAmount,
        total: acc.total + total,
        promoCodeEligibleAmount: selectedInventory.get('enablePromocodes')
          ? acc.promoCodeEligibleAmount + total
          : acc.promoCodeEligibleAmount,
      }
    },
    {
      basePrice: 0,
      serviceCharge: 0,
      tax: 0,
      requiredTip: 0,
      customTip: 0,
      tip: 0,
      subTotal: 0,
      additionalFeeAmount: 0,
      additionalFeeTaxAmount: 0,
      discount: 0,
      total: 0,
      promoCodeEligibleAmount: 0,
    }
  )

  let discount = 0
  const { promoCode } = state.entities
  if (promoCode && promoCodeEligibleAmount > 0) {
    discount = getDiscountedPrice(promoCodeEligibleAmount, promoCode)
  }

  return {
    basePrice: Number(roundMoney(basePrice)),
    serviceCharge: Number(roundMoney(serviceCharge)),
    tax: Number(roundMoney(tax)),
    requiredTip: Number(roundMoney(requiredTip)),
    customTip: Number(roundMoney(customTip)),
    tip: Number(roundMoney(tip)),
    subTotal: Number(roundMoney(subTotal)),
    additionalFeeAmount: Number(roundMoney(additionalFeeAmount)),
    additionalFeeTaxAmount: Number(roundMoney(additionalFeeTaxAmount)),
    discount: Number(roundMoney(discount)),
    total: Number(roundMoney(total - discount)),
  }
}

export function getSelectedInventoryCart(state) {
  return state.inventoryCart.cart.filter(({ quantity, availabilityId }) => quantity > 0 && availabilityId !== undefined)
}
