import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import Modal from 'svr/lib/Modal/Modal'
import TagSelector from 'widget/dining/containers/TagSelector'
import { saveTagGroup } from 'widget/events/actions/forms'
import { dismissModal } from 'widget/events/actions/navigation'
import { selectLanguageStrings } from 'widget/events/selectors/languageSelectors'
import { modalTypes } from 'widget/events/utils/constantTypes'
import { RESERVATION_TYPE } from '../reducers/inventoryReducer'
import { getSelectedInventoryCart } from '../selectors/inventoryCartSelector'
import type { InventoryCartState } from '../reducers/inventoryCartReducer'

interface TagGroup {
  groupId: string
  tagGroup: { [key: string]: string }
}

interface StateProps {
  colorModalBackground: string
  colorPrimary: string
  fontsColorButton: string
  isMobile: boolean
  modalType: string
  tagGroups: TagGroup[] | undefined
  tagTranslations: string
  textCancelButtonLabel: string
  textSaveButtonLabel: string
  textTagLabelYourGuests: string
  textTagLabelYours: string
}

interface DispatchProps {
  dismissModal: () => void
  saveTagGroups: (tagGroups: TagGroup[]) => void
}

function ModalManager(props: StateProps & DispatchProps) {
  const {
    colorPrimary,
    colorModalBackground,
    dismissModal,
    fontsColorButton,
    isMobile,
    modalType,
    saveTagGroups,
    tagGroups,
    tagTranslations,
    textCancelButtonLabel,
    textSaveButtonLabel,
    textTagLabelYourGuests,
    textTagLabelYours,
  } = props
  if (!tagGroups) {
    return null
  }
  return (
    <Modal
      body={
        <TagSelector
          tagGroups={tagGroups}
          groupNames={[textTagLabelYours, textTagLabelYourGuests]}
          onCancel={dismissModal}
          onSave={saveTagGroups}
          colorPrimary={fontsColorButton}
          colorSecondary={colorPrimary}
          saveButtonLabel={textSaveButtonLabel}
          cancelButtonLabel={textCancelButtonLabel}
          tagTranslations={tagTranslations}
        />
      }
      modalType={_.startCase(_.capitalize(modalType))}
      dismissModal={dismissModal}
      backgroundColor={colorModalBackground}
      overflowX="hidden"
      customStyles={{
        top: '100px',
        transform: 'translate(-50%, 0)',
        maxHeight: isMobile ? '65vh' : '80vh',
      }}
    />
  )
}

// state is all in js
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  const languageStrings = selectLanguageStrings(state)

  const tagTranslations = !_.isEmpty(state.languages.selectedLanguage)
    ? state.languages.tagLanguageStrings[state.languages.selectedLanguage]
    : {}

  // show/hide logic for dietary restrictions tabs
  // for client tags
  // - only show for reservation inventory
  // for reservation tags
  // - for reservation inventory, show if guests > 1 under "your guests" tab
  // - for guestlist inventory, always show under single tab

  const dietTagGroups = []
  const cart: InventoryCartState['cart'] = getSelectedInventoryCart(state)
  const isReservationType = cart.some(({ inventoryType }) => inventoryType === RESERVATION_TYPE)
  const dietaryRestrictionsId = state.entities.tags.getIn(['clientTagGroups', 'dietaryPreference'])
  if (dietaryRestrictionsId && isReservationType) {
    dietTagGroups.push(state.entities.tags.getIn(['tagGroups', dietaryRestrictionsId]).toJS())
  }

  const totalNumberOfGuests = cart.reduce((total: number, { quantity, inventoryId }) => {
    const currInventory = state.entities.inventory.get(inventoryId)
    const entryPerReservation = currInventory.get('entryPerReservation')
    const numOfGuests = quantity * entryPerReservation
    return total + numOfGuests
  }, 0)

  const dietaryRestrictionsGuestId = state.entities.tags.getIn(['reservationTagGroups', 'dietaryPreference'])
  if (dietaryRestrictionsGuestId && (!isReservationType || (isReservationType && totalNumberOfGuests > 1))) {
    dietTagGroups.push(state.entities.tags.getIn(['tagGroups', dietaryRestrictionsGuestId]).toJS())
  }

  const occasionTagGroups = []
  const specialOccasionsId = state.entities.tags.getIn(['reservationTagGroups', 'specialOccasion'])
  if (specialOccasionsId) {
    occasionTagGroups.push(state.entities.tags.getIn(['tagGroups', specialOccasionsId]).toJS())
  }

  const modalType = state.formFields.get('displayModalType')
  const tagGroups = {
    [modalTypes.DIET_TAG_SELECT]: dietTagGroups,
    [modalTypes.SPECIAL_OCCASION_SELECT]: occasionTagGroups,
  }[modalType]
  return {
    modalType,
    tagGroups,
    tagTranslations,

    // colors
    colorPrimary: state.widgetSettings.colorPrimary,
    colorModalBackground: state.widgetSettings.colorModalBackground,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,

    // text
    textTagLabelYours: languageStrings.textTagLabelYours,
    textTagLabelYourGuests: languageStrings.textTagLabelYourGuests,
    textSaveButtonLabel: languageStrings.textSaveButtonLabel,
    textCancelButtonLabel: languageStrings.textCancelButtonLabel,

    isMobile: state.app.isMobile,
  }
}

// actions are all in js
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => ({
  dismissModal: () => dispatch(dismissModal()),
  saveTagGroups: (tagGroups: TagGroup[]) => {
    _.each(tagGroups, (tagGroup, groupId) => dispatch(saveTagGroup(tagGroup, groupId)))
  },
})

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(ModalManager)
