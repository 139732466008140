import priceFormatter from 'currency-formatter'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as styles from '../assets/styles/bookingSuccess'
import { getDateFormatMedium, getFormattedTime } from '../utils/date'
import { getSelectedInventoryCart } from '../selectors/inventoryCartSelector'
import { GUESTLIST_FREE_TYPE, GUESTLIST_TYPE, REQUEST_TYPE } from '../reducers/inventoryReducer'

class BookingSuccess extends Component {
  constructor(props) {
    super(props)
    this.appWrapper = document.getElementById('bw-wrapper')
  }

  componentDidMount() {
    this.appWrapper.scrollTop = 0
  }

  getHeader() {
    if (this.props.hasRequestType) {
      return (
        <div style={styles.successHeader}>
          <span style={styles.official}>Done!</span>
          <span style={styles.onTheBooks}>Your request has been submitted</span>
        </div>
      )
    }
    return (
      <div style={styles.successHeader}>
        <span style={styles.official}>Thank you.</span>
        <span style={styles.onTheBooks}>We look forward to welcoming you.</span>
      </div>
    )
  }

  render() {
    try {
      // eslint-disable-next-line no-eval
      window.eval(widgetInit.customSuccess)
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return (
      <div style={styles.successWraper}>
        {this.getHeader()}
        <div style={styles.successBody}>
          {this.props.hasRequestType ? (
            <div style={styles.bodyText}>
              <span>someone will be in touch shortly at</span>
              <span style={styles.email}>{this.props.email}</span>
            </div>
          ) : (
            <div>
              <div style={styles.bodyText}>
                <span>Your confirmation and details have been sent to</span>
                <span style={styles.email}>{this.props.email}</span>
              </div>
              <div style={styles.summaryDetails}>
                <div>
                  {this.props.firstName} {this.props.lastName}
                </div>
                <div>
                  {this.props.venueName} - {this.props.eventName}
                </div>
                <div>
                  {this.props.date} &#x2022; {this.props.eventTime}
                </div>
                {this.props.inventoryItems.map(({ inventoryName, quantity, guestsDisplayText }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} style={{ marginTop: '10px' }}>
                    <div>{guestsDisplayText}</div>
                    <div>
                      ({quantity}) {inventoryName}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div>
            {this.props.facebookLink || this.props.instagramLink || this.props.twitterLink || this.props.snapchatLink ? (
              <span style={styles.followText}>follow us on</span>
            ) : null}
            <div style={styles.iconBox}>
              {this.props.facebookLink ? (
                <a target="_blank" rel="noopener noreferrer" href={this.props.facebookLink}>
                  <div style={styles.socialIcon}>
                    <i style={styles.fontIcon} className="fa fa-facebook" />
                  </div>
                </a>
              ) : null}
              {this.props.instagramLink ? (
                <a target="_blank" rel="noopener noreferrer" href={this.props.instagramLink}>
                  <div style={styles.socialIcon}>
                    <i style={styles.fontIcon} className="fa fa-social-instagram" />
                  </div>
                </a>
              ) : null}
              {this.props.twitterLink ? (
                <a target="_blank" rel="noopener noreferrer" href={this.props.twitterLink}>
                  <div style={styles.socialIcon}>
                    <i style={styles.fontIcon} className="fa fa-twitter" />
                  </div>
                </a>
              ) : null}
              {this.props.snapchatLink ? (
                <a target="_blank" rel="noopener noreferrer" href={this.props.snapchatLink}>
                  <div style={styles.socialIcon}>
                    <i style={styles.fontIcon} className="fa fa-social-snapchat" />
                  </div>
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const isGuestlistType = type => [GUESTLIST_TYPE, GUESTLIST_FREE_TYPE].includes(type)

const generateGuestsDisplayText = (isGuestlist, minPrice, quantity, maxGuests, currencyCode, textMinimumSpend) => {
  const minDollarText = (function () {
    if (!minPrice) {
      return ''
    }
    const minDollarAmount = quantity * minPrice
    const minDollarAmountFormatted = priceFormatter.format(minDollarAmount, {
      code: currencyCode,
    })
    return `, ${minDollarAmountFormatted} ${textMinimumSpend}`
  })()

  const guests = `${maxGuests} guest${maxGuests === 1 ? '' : 's'}`
  return isGuestlist ? guests + minDollarText : `Up to ${guests}${minDollarText}`
}

const mapStateToProps = state => {
  let email
  let firstName
  let lastName
  let date
  let eventName
  let eventTime
  const inventoryItems = []

  if (widgetInit.successBypass) {
    email = state.actualInfo.email
    firstName = state.actualInfo.firstName
    lastName = state.actualInfo.lastName
    date = state.actualInfo.date
    eventName = state.actualInfo.eventName
    eventTime = state.actualInfo.time

    const { availability } = state.actualInfo
    const { min_price: minPrice, quantity, inventory_type: inventoryType, inventory_name: inventoryName } = availability
    const maxGuests = availability.quantity * availability.entry_per_reservation
    inventoryItems.push({
      inventoryName,
      inventoryType,
      quantity,
      guestsDisplayText: generateGuestsDisplayText(
        isGuestlistType(inventoryType),
        minPrice,
        quantity,
        maxGuests,
        state.venueInfo.currencyCode,
        state.widgetSettings.textMinimumSpend
      ),
    })
  } else {
    email = state.formFields.get('email')
    firstName = state.formFields.get('firstName')
    lastName = state.formFields.get('lastName')
    date = state.userSelection.get('date')

    const eventId = state.userSelection.get('eventId')
    const event = state.entities.events.get(eventId)
    eventName = event.get('name')
    eventTime = event.get('eventStartTime')

    const cart = getSelectedInventoryCart(state)
    cart.forEach(({ inventoryId, quantity }) => {
      const inventory = state.entities.inventory.get(inventoryId)
      const inventoryType = inventory.get('inventoryType')
      const maxGuests = quantity * inventory.get('entryPerReservation')
      const minPrice = inventory.get('minPrice')
      inventoryItems.push({
        inventoryName: inventory.get('inventoryName'),
        inventoryType,
        quantity,
        guestsDisplayText: generateGuestsDisplayText(
          isGuestlistType(inventoryType),
          minPrice,
          quantity,
          maxGuests,
          state.venueInfo.currencyCode,
          state.widgetSettings.textMinimumSpend
        ),
      })
    })
  }

  const { facebookLink, twitterLink, snapchatLink, instagramLink } = state.venueInfo

  return {
    email,
    firstName,
    lastName,
    date: getDateFormatMedium(date),
    venueName: state.venueInfo.name,
    eventName,
    eventTime: getFormattedTime(eventTime),
    facebookLink,
    twitterLink,
    snapchatLink,
    instagramLink,
    inventoryItems,
    hasRequestType: inventoryItems.some(item => item.inventoryType === REQUEST_TYPE),
  }
}

export default connect(mapStateToProps, null)(BookingSuccess)
