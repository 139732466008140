import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddressInput from 'svr/component-lib/Generic/TextInputs/AddressInput'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import { handleNetworkPayment, isNetworkRedux } from 'svr/lib/Payments/NetworkRedux'
import { postCheckoutFailure } from 'widget/events/actions/services'
import { validateNotEmpty, validateCardMonthExp, validateCardYearExp, validateCardCvv } from 'svr/common/Validators'
import CardNumberInput from '../../../../lib/TextInputs/CardNumberInput'
import TextInput from '../../../../lib/TextInputs/TextInput'
import { changeFormField } from '../actions/forms'
import * as styles from '../assets/styles/checkout'

class CheckoutPayment extends Component {
  constructor(props) {
    super(props)
    this.state = { address: null }
    this.handleFirstNameChange = this.props.changeFormField.bind(this, 'cardFirstName')
    this.handleLastNameChange = this.props.changeFormField.bind(this, 'cardLastName')
    this.handleCardNumChange = this.props.changeFormField.bind(this, 'cardNum')
    this.handleMonthExpChange = this.props.changeFormField.bind(this, 'cardMonthExp')
    this.handleYearExpChange = this.props.changeFormField.bind(this, 'cardYearExp')
    this.handleCvvChange = this.props.changeFormField.bind(this, 'cardCvv')
    this.handleZipCodeChange = this.props.changeFormField.bind(this, 'cardZipCode')
  }

  handleAddressChange = (address, place) => {
    const address1 = []
    let locality
    let administrativeArea
    let postalCode
    let countryCode
    if (!place) {
      this.setState({ address: '' })
      this.props.changeFormField('address1', null)
      this.props.changeFormField('locality', null)
      this.props.changeFormField('administrativeArea', null)
      this.props.changeFormField('countryCode', null)
      this.props.changeFormField('postalCode', null)
      return
    }
    // https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
    _.each(place.address_components, addressComponent => {
      if (['street_address', 'street_number', 'route'].some(r => addressComponent.types.includes(r))) {
        address1.push(addressComponent.long_name)
      }
      if (addressComponent.types.includes('locality')) {
        locality = addressComponent.long_name
      }
      if (!locality && addressComponent.types.includes('administrative_area_level_1')) {
        locality = addressComponent.long_name
      }
      if (addressComponent.types.includes('administrative_area_level_1')) {
        administrativeArea = addressComponent.short_name
      }
      if (addressComponent.types.includes('country')) {
        countryCode = addressComponent.short_name
      }
      if (addressComponent.types.includes('postal_code')) {
        postalCode = addressComponent.long_name
      }
    })
    this.setState({
      address,
    })
    this.props.changeFormField('address1', address1.join(' '))
    this.props.changeFormField('locality', locality)
    this.props.changeFormField('administrativeArea', administrativeArea)
    this.props.changeFormField('countryCode', countryCode)
    this.props.changeFormField('postalCode', postalCode)
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(this.props.networkReduxResponse) && !_.isEmpty(nextProps.networkReduxResponse)) {
      this.props.handleNetworkPayment(nextProps.networkReduxResponse, postCheckoutFailure)
    }

    const { venueInfo } = this.props

    if (isNetworkRedux(venueInfo) && _.isEmpty(this.props.paymentPendingResponse) && !_.isEmpty(nextProps.paymentPendingResponse)) {
      this.props.handleNetworkPayment(nextProps.paymentPendingResponse, postCheckoutFailure)
    }
  }

  componentDidMount() {
    if ([AccountTypes.NETWORK_REDUX, AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE].includes(this.props.paymentType)) {
      window.NI.mountCardInput('ccFormMountId', {
        style: {
          main: {
            margin: 0,
          },
          base: {
            borderStyle: 'solid',
            borderWidth: '0 0 1px 0',
            borderColor: '#eee',
            fontFamily: 'Roboto',
            fontUrl: `${this.props.baseUrl}${this.props.mediaUrl}css/fonts.css`,
            fontSize: '14px',
          },
          input: {
            color: '#000',
            margin: 0,
            padding: '10px 10px 10px 17px',
          },
        },
        // API Key for WEB SDK from the portal; the
        // fail string is needed to prod the SDK into
        // triggering its own onFail method. smh.
        apiKey: this.props.paymentGatewayApiKey || 'fail',

        // outlet reference from the portal
        outletRef: this.props.accountId,

        // These apply to api validation
        onSuccess: () => {},
        onFail: element => this.props.initializationFailure(element, 'Unable to process request. Please try again'),

        onChangeValidStatus: () => {},
      })
    }
  }

  render() {
    const {
      cardFirstName,
      cardLastName,
      cardNum,
      cardMonthExp,
      cardYearExp,
      cardCvv,
      cardZipCode,
      needsZipCode,
      paymentFields,
      paymentType,
      topElementId,
      formErrors,
      fontsColorPrimary,
      fontsColorCheckoutInactive,
      colorCheckoutCellBackground,
      colorLines,
      mediaUrl,
      textInputProps,
    } = this.props

    const boxStyle = needsZipCode ? styles.smallFormFourBox : styles.smallFormThreeBox
    const isValidAddress = !this.props.submitClicked || this.state.address

    return (
      <div id={topElementId} style={styles.sectionWrapper}>
        <div style={styles.labelWrapper}>
          <span
            style={_.assign({}, styles.sectionLabel, {
              color: fontsColorPrimary,
            })}
          >
            Payment
          </span>
        </div>
        <div style={styles.infoForm}>
          <div
            id="card-name-line-input"
            style={_.assign({}, styles.formLine, styles.topBorderRadius, {
              backgroundColor: colorCheckoutCellBackground,
            })}
          >
            <div style={styles.formBox}>
              <TextInput
                placeholder="First Name *"
                limitType="name"
                value={cardFirstName}
                charLimit={50}
                validator={validateNotEmpty}
                isValid={!formErrors.cardFirstName}
                onChange={this.handleFirstNameChange}
                style={[styles.firstNameInput, { borderRadius: '8px 0 0 0' }]}
                ref={input => {
                  paymentFields.cardFirstName = input
                }}
                {...textInputProps}
              />
            </div>
            <div style={styles.formBox}>
              <TextInput
                placeholder="Last Name *"
                value={cardLastName}
                onChange={this.handleLastNameChange}
                limitType="name"
                charLimit={50}
                validator={validateNotEmpty}
                isValid={!formErrors.cardLastName}
                style={[styles.lastNameInput, { borderRadius: '0 8px 0 0' }]}
                ref={input => {
                  paymentFields.cardLastName = input
                }}
                {...textInputProps}
              />
            </div>
          </div>
          <hr
            style={_.assign({}, styles.formLineSeperator, {
              borderColor: colorLines,
            })}
          />
          {/* eslint-disable-next-line no-nested-ternary */}
          {[AccountTypes.NETWORK_REDUX, AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE].includes(paymentType) ? (
            <div>
              <div id="ccFormMountId" style={{ height: '210px' }} />
              <div id="cc3dsMountId">
                <div id="loadingIndicator" />
              </div>
            </div>
          ) : (
            <div>
              <div
                id="card-input"
                style={_.assign({}, styles.formLine, {
                  backgroundColor: colorCheckoutCellBackground,
                })}
              >
                <div style={styles.formBox}>
                  <CardNumberInput
                    placeholder="Card Number *"
                    mediaUrl={mediaUrl}
                    value={cardNum}
                    isValid={!formErrors.cardNum}
                    onChange={this.handleCardNumChange}
                    style={styles.singleFieldInput}
                    ref={input => {
                      paymentFields.cardNum = input
                    }}
                    {...textInputProps}
                  />
                </div>
              </div>
              <hr
                style={_.assign({}, styles.formLineSeperator, {
                  borderColor: colorLines,
                })}
              />
              <div
                id="card-info-line-input"
                style={_.assign({}, styles.formLine, styles.bottomBorderRadius, {
                  backgroundColor: colorCheckoutCellBackground,
                })}
              >
                <div style={_.assign({}, styles.formBox, boxStyle)}>
                  <TextInput
                    placeholder="mm *"
                    limitType="number"
                    charLimit={2}
                    value={cardMonthExp}
                    validator={validateCardMonthExp}
                    isValid={!formErrors.cardMonthExp}
                    onChange={this.handleMonthExpChange}
                    textColor={this.props.fontsColorPrimary}
                    style={styles.thirdBox}
                    ref={input => {
                      paymentFields.cardMonthExp = input
                    }}
                    {...textInputProps}
                  />
                </div>
                <div style={_.assign({}, styles.formBox, boxStyle)}>
                  <TextInput
                    placeholder="yyyy *"
                    limitType="number"
                    charLimit={4}
                    value={cardYearExp}
                    validator={validateCardYearExp}
                    isValid={!formErrors.cardYearExp}
                    onChange={this.handleYearExpChange}
                    textColor={this.props.fontsColorPrimary}
                    style={styles.thirdBox}
                    ref={input => {
                      paymentFields.cardYearExp = input
                    }}
                    {...textInputProps}
                  />
                </div>
                <div style={_.assign({}, styles.formBox, boxStyle)}>
                  <TextInput
                    placeholder="cvv *"
                    limitType="number"
                    charLimit={4}
                    value={cardCvv}
                    validator={validateCardCvv}
                    isValid={!formErrors.cardCvv}
                    onChange={this.handleCvvChange}
                    textColor={this.props.fontsColorPrimary}
                    style={styles.thirdBox}
                    ref={input => {
                      paymentFields.cardCvv = input
                    }}
                    {...textInputProps}
                  />
                </div>

                {needsZipCode ? (
                  <div style={styles.formBox}>
                    <TextInput
                      placeholder="zip code *"
                      charLimit={7}
                      value={cardZipCode}
                      validator={validateNotEmpty}
                      isValid={!formErrors.zipCode}
                      onChange={this.handleZipCodeChange}
                      style={styles.thirdBox}
                      ref={input => {
                        paymentFields.zipCode = input
                      }}
                      {...textInputProps}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {this.props.isCybersourceBillingAddressRequired ? (
            <>
              <hr style={{ ...styles.formLineSeperator, borderColor: colorLines }} />
              <div style={{ ...styles.formLine, ...styles.bottomBorderRadius, backgroundColor: colorCheckoutCellBackground }}>
                <AddressInput
                  googlePlacesApiKey={widgetInit.googlePlacesApiKey}
                  placeholder="Enter your billing address"
                  showLabel={false}
                  value={this.state.address}
                  isValid={isValidAddress}
                  onChange={this.handleAddressChange}
                  testId="sr-input-address"
                  inputStyles={{ marginLeft: '20px', background: 'transparent' }}
                  backgroundColor={{ colorCheckoutCellBackground }}
                  placeholderColor={{ fontsColorCheckoutInactive }}
                  errorColor="#d01a21"
                  hideBorder={isValidAddress}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { formFields } = state
  return {
    cardFirstName: formFields.get('cardFirstName'),
    cardLastName: formFields.get('cardLastName'),
    cardNum: formFields.get('cardNum'),
    cardMonthExp: formFields.get('cardMonthExp'),
    cardYearExp: formFields.get('cardYearExp'),
    cardCvv: formFields.get('cardCvv'),
    cardZipCode: formFields.get('cardZipCode'),
    needsZipCode: state.venueInfo.needsZipCode,
    paymentType: state.venueInfo.paymentType,
    paymentGatewayApiKey: state.venueInfo.paymentGatewayApiKey,
    accountId: state.venueInfo.accountId,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorCheckoutInactive: state.widgetSettings.fontsColorCheckoutInactive,
    colorCheckoutCellBackground: state.widgetSettings.colorCheckoutBackground,
    colorLines: state.widgetSettings.colorLines,
    formErrors: state.formFields.get('formErrors').toJS(),
    baseUrl: state.widgetSettings.baseUrl,
    mediaUrl: state.widgetSettings.mediaUrl,
    networkReduxResponse: state.payment.networkReduxResponse,
    venueInfo: state.venueInfo,
    paymentPendingResponse: state.payment.paymentPendingResponse,
  }
}

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
  handleNetworkPayment: (response, postCheckoutFailure) => {
    dispatch(handleNetworkPayment(response, postCheckoutFailure))
  },
})

CheckoutPayment = connect(mapStateToProps, mapDispatchToProps)(CheckoutPayment)

export default CheckoutPayment
