/* global widgetInit */
import _ from 'lodash'
import { createSelector } from 'reselect'
import { getSelectedInventoryCart } from './inventoryCartSelector'

export const selectVenueGroupMarketingPolicy = () => widgetInit.venueInfo.marketing_opt_in_venue_group_policy

export const selectVenueSpecificMarketingPolicy = () => widgetInit.venueInfo.marketing_opt_in_policy

export const selectVenueSmsMarketingPolicy = () => widgetInit.venueInfo.marketing_opt_in_sms_policy

export const selectReservationSmsOptInPolicy = () => widgetInit.venueInfo.reservation_sms_opt_in_policy
export const selectTailoredCommunicationOptInPolicy = () => widgetInit.venueInfo.tailored_communication_opt_in
const selectedLanguageCode = () => widgetInit.venueInfo.selected_language_code
const paymentPolicyMap = () => widgetInit.venueInfo.payment_policy_map

const getUserSelection = state => state.userSelection

const getInventoryEntities = state => state.entities.inventory

const getEventEntities = state => state.entities.events

export const selectBookingPolicy = createSelector(
  [getUserSelection, getInventoryEntities, getEventEntities, paymentPolicyMap, selectedLanguageCode, getSelectedInventoryCart],
  (userSelection, inventoryEntities, eventEntities, paymentPolicyMap, selectedLanguageCode, selectedInventoryCart) => {
    const policies = []
    selectedInventoryCart.forEach(({ inventoryId }) => {
      const currInventory = inventoryEntities.get(inventoryId)
      const inventoryName = currInventory.get('inventoryName')

      if (currInventory.get('customCancellationPolicy')) {
        policies.push({ name: [inventoryName], policy: currInventory.get('customCancellationPolicy') })
        return
      }

      const inventoryCancellationPolicyId = currInventory.get('cancellationPolicyId')
      if (paymentPolicyMap && inventoryCancellationPolicyId) {
        if (paymentPolicyMap[inventoryCancellationPolicyId]) {
          policies.push({ name: [inventoryName], policy: paymentPolicyMap[inventoryCancellationPolicyId][selectedLanguageCode] })
        } else {
          policies.push({ name: [inventoryName], policy: _.values(paymentPolicyMap)[0][selectedLanguageCode] })
        }
        return
      }

      const { eventId } = userSelection.toJS()
      const selectedEvent = eventEntities.toJS()[eventId]
      policies.push({ name: [inventoryName], policy: getDefaultPolicy(selectedEvent, paymentPolicyMap, selectedLanguageCode) })
    })

    return getFormattedPolicies(policies)
  }
)

const getDefaultPolicy = (selectedEvent, paymentPolicyMap, selectedLanguageCode) => {
  if (selectedEvent.customCancellationPolicy) {
    return selectedEvent.customCancellationPolicy
  }

  const eventCancellationPolicyId = selectedEvent.cancellationPolicyId
  if (paymentPolicyMap && eventCancellationPolicyId) {
    if (paymentPolicyMap[eventCancellationPolicyId]) {
      return paymentPolicyMap[eventCancellationPolicyId][selectedLanguageCode]
    }

    return _.values(paymentPolicyMap)[0][selectedLanguageCode]
  }

  return widgetInit.venueInfo.event_widget_purchase_policy
}

const getFormattedPolicies = policies => {
  const uniquePolicies = policies.reduce((acc, current) => {
    const existingPolicy = acc.find(policy => policy.policy === current.policy)
    if (existingPolicy) {
      existingPolicy.name.push(current.name)
    } else {
      acc.push({ name: [current.name], policy: current.policy })
    }
    return acc
  }, [])

  if (uniquePolicies.length === 1) {
    return uniquePolicies[0].policy
  }

  const policyListHtml = uniquePolicies
    .map(policy => {
      const policyNamesHtml = policy.name.join(', ')
      return `<li><strong>${policyNamesHtml}:</strong> ${policy.policy}</li>`
    })
    .join('')

  return `<ul>${policyListHtml}</ul>`
}
