import { fetchVerifyPromoCode } from 'widget/events/actions/services'
import { calculatePricingDetails } from '../selectors/inventoryCartSelector'
import Analytics from '../services/AnalyticsServices'
import { promoCodeErrorTypes } from '../utils/constantTypes'
import {
  CHANGE_PHONE_NUM,
  CHANGE_COUNTRY_FLAG,
  VALIDATE_ALL_FIELDS,
  CHANGE_FORM_FIELD,
  CHANGE_CAPTCHA_FIELD,
  VALIDATE_CAPTCHA_FIELD,
  MANDATE_POLICIES,
  PASS_STRIPE_CARD_ELEMENT,
  ADD_PROMO_CODE,
  INVALIDATE_PROMO_CODE_ATTEMPT,
  REMOVE_PROMO_CODE,
  SAVE_TAG_GROUP,
  TRY_VERIFY_PROMO_CODE,
  VERIFY_PROMO_CODE_SUCCESS,
  VERIFY_PROMO_CODE_FAILURE,
  UPDATE_PRICES,
} from './ActionTypes'

export const changePhoneNum = changeTo => ({ type: CHANGE_PHONE_NUM, changeTo })

export const changeFlag = (changeTo, selectedCountry) => ({
  type: CHANGE_COUNTRY_FLAG,
  changeTo,
  selectedCountry,
})

export const validateAllFields = formErrors => ({
  type: VALIDATE_ALL_FIELDS,
  formErrors,
})

export const changeFormField = (field, changeTo) => ({
  type: CHANGE_FORM_FIELD,
  field,
  changeTo,
})

export const changeCaptchaField = recaptchaValue => ({
  type: CHANGE_CAPTCHA_FIELD,
  recaptchaValue,
})

export const completeCaptchaField = recaptchaValue => ({
  type: VALIDATE_CAPTCHA_FIELD,
  recaptchaValue,
})

export const mandatePolicies = formErrors => ({
  type: MANDATE_POLICIES,
  formErrors,
})

export const passCardElement = element => ({
  type: PASS_STRIPE_CARD_ELEMENT,
  element,
})

export const addPromoCode = promoCode => dispatch => {
  Analytics.appliedPromoCode(promoCode)
  dispatch({
    type: ADD_PROMO_CODE,
    promoCode,
  })
}

export const removePromoCode = () => (dispatch, getState) => {
  dispatch({ type: REMOVE_PROMO_CODE })

  const state = getState()
  const pricingDetails = calculatePricingDetails(state)
  dispatch({
    type: UPDATE_PRICES,
    pricingDetails,
  })
}

export const tryVerifyPromoCode = () => ({ type: TRY_VERIFY_PROMO_CODE })

export const verifyPromoCodeSuccess = data => (dispatch, getState) => {
  dispatch({
    type: VERIFY_PROMO_CODE_SUCCESS,
    data,
  })

  const state = getState()
  const pricingDetails = calculatePricingDetails(state)
  dispatch({
    type: UPDATE_PRICES,
    pricingDetails,
  })
}

export const verifyPromoCodeFailure = error => {
  let errorMessage
  switch (error) {
    case promoCodeErrorTypes.EXHAUSTED:
      errorMessage = 'Promo code has already been used'
      break
    default:
      errorMessage = 'Invalid promo code'
      break
  }
  return { type: VERIFY_PROMO_CODE_FAILURE, errorMessage }
}

export const invalidatePromoCodeAttempt = () => ({
  type: INVALIDATE_PROMO_CODE_ATTEMPT,
})

export const verifyPromoCode = () => (dispatch, getState) => {
  const state = getState()
  dispatch(tryVerifyPromoCode())
  fetchVerifyPromoCode(state)
    .then(response => {
      if (!response.errors) {
        dispatch(verifyPromoCodeSuccess(response))
      } else if (state.formFields.get('promoCodeInfo')) {
        dispatch(invalidatePromoCodeAttempt())
      } else {
        dispatch(verifyPromoCodeFailure(response.errors[0]))
      }
    })
    .catch(() => {
      dispatch(verifyPromoCodeFailure('Unable to process promo code. Please try again'))
    })
}

export const checkPromoCodeForCheckout = () => (dispatch, getState) => {
  const state = getState()
  const promoCode = state.formFields.get('promoCode')
  if (promoCode) {
    fetchVerifyPromoCode(state).then(response => {
      if (response.errors) {
        dispatch(removePromoCode())
      }
    })
  }
}

export const saveTagGroup = (tagGroup, groupId) => ({
  type: SAVE_TAG_GROUP,
  tagGroup,
  groupId,
})
