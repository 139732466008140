import _ from 'lodash'
import moment from 'moment-timezone'
import { CyberEncrypt } from 'svr/lib/Payments/CyberSource'
import { AESEncrypt } from 'svr/lib/Payments/Network'
import { creditCardPaymentRuleTypes } from 'widget/dining/utils/constantTypes'
import { selectors as upsellsSelectors } from 'widget/modules/upsells'
import { getLocaleDateFormat } from '../../../events/src/utils/date'
import { selectPricingData } from '../payments/paymentSelectors'
import { selectTimeSlotEntity } from './selectors'

export const convertValidDatesFromApi = validDates => _.reduce(validDates, (dateMap, date) => _.assign(dateMap, { [date]: true }), {})

export const convertExperienceAvailabilityFromApi = (data, selectedPartySize, venueToday) => {
  const availability = _.reduce(
    data,
    (accum, partySizeMap, date) => {
      _.each(partySizeMap, (isAvail, partySize) => {
        if (!accum[partySize]) {
          // eslint-disable-next-line no-param-reassign
          accum[partySize] = {}
        }

        if (isAvail && date) {
          if (venueToday) {
            const todayDateObj = new Date(venueToday.year(), venueToday.month(), venueToday.date())
            const [year, month, day] = date.split('-').map(Number)
            const dateObj = new Date(year, month - 1, day)
            if (dateObj >= todayDateObj) {
              // eslint-disable-next-line no-param-reassign
              accum[partySize][date] = true
            }
          } else {
            // if no venueToday found in state, revert to old logic
            // eslint-disable-next-line no-param-reassign
            accum[partySize][date] = true
          }
        }
      })
      return accum
    },
    {}
  )

  let firstAvailable = null
  if (!data || !Object.keys(data).length) {
    return { data, firstAvailable }
  }

  if (selectedPartySize) {
    const [firstAvailable] = Object.keys(availability[selectedPartySize] || {}).sort()
    return { availability, firstAvailable }
  }

  const partySizeKeys = Object.keys(availability)
    .map(Number)
    .sort((a, b) => a - b)
  partySizeKeys.forEach(partySizeKey => {
    if (firstAvailable) {
      return
    }
    const partySizeAvailability = Object.keys(availability[partySizeKey] || {}).sort()
    if (partySizeAvailability.length) {
      // eslint-disable-next-line prefer-destructuring
      firstAvailable = partySizeAvailability[0]
    }
  })
  return { availability, firstAvailable }
}

export const convertToFormData = data => {
  const prunedData = _.pickBy(data, Boolean)
  return _.reduce(
    prunedData,
    (formData, val, key) => {
      formData.append(key, val)
      return formData
    },
    new FormData()
  )
}

export const prepDataForModify = state => {
  const phoneNumber = state.formFields.get('phoneNumber')
  const selectedTimeSlot = state.search.get('selectedTimeSlot')
  const dateStr = selectedTimeSlot.format('Y-MM-DD')
  const timeStr = selectedTimeSlot.format('h:mm A')
  const accessPersistentId = state.search.get('accessPersistentId')
  const timeSlotVenue = state.searchResults.get('timeSlotVenue')
  const shiftPersistentId = state.availabilityLookup.getIn([timeSlotVenue, dateStr, timeStr, accessPersistentId, 'shiftPersistentId'])

  return {
    actual_id: state.modifyReservation.get('actualId'),
    venue_id: state.venueEntities[timeSlotVenue].id,
    party_size: state.search.get('partySize'),
    date: selectedTimeSlot.format('MM-DD-Y'),
    time: timeStr,
    access_persistent_id: accessPersistentId,
    shift_persistent_id: shiftPersistentId,
    reservation_hold_id: state.reservationHold.get('reservationHoldId'),
    ...(phoneNumber && {
      phone_number: phoneNumber,
      country_code: state.formFields.get('phoneNumberLocale'),
      dial_code: state.formFields.get('dialCode'),
    }),
    modify_upgrades_token:
      state.modifyReservation.get('token') &&
      state.modifyReservation.get('isModifyResUpgradesMode') &&
      decodeURIComponent(state.modifyReservation.get('token')),
    agreed_to_gdpr_dietary_optin: state.modifyReservation.get('agreedToDietaryGdprOptinOn'),
  }
}

export const prepUserData = state => {
  const userData = {
    first_name: state.formFields.get('firstName'),
    last_name: state.formFields.get('lastName'),
    email: state.formFields.get('email'),
    phone_number: state.formFields.get('phoneNumber'),
    birthday: state.formFields.get('birthday'),
    postal_code: state.formFields.get('postalCode'),
    country_code: state.formFields.get('phoneNumberLocale'),
    dial_code: state.formFields.get('dialCode'),
    reservation_notes: state.formFields.get('note'),
    loginSite: state.user.get('site'),
    location: state.user.get('location'),
    userId: state.user.get('userId'),
    picture: state.user.get('picture'),
    display_reservation_sms_opt_in: state.formFields.get('displayReservationSmsOptInPolicy'),
    display_tailored_communication_opt_in: state.formFields.get('displayTailoredCommunicationOptInPolicy'),
    preferred_language_code: state.languages.clientPreferredLanguage || state.languages.selectedLanguage,
    salutation: state.formFields.get('salutation'),
  }

  if (userData.birthday) {
    if (getLocaleDateFormat(state.venueInfo.municipality.locale) === 'dd/mm') {
      // save the date in default US format for proper display formatting elsewhere
      userData.birthday = `${userData.birthday.split('/')[1]}/${userData.birthday.split('/')[0]}`
    }
  }

  if (state.formFields.get('displayVenueGroupMarketingOptInPolicy')) {
    _.extend(userData, {
      venue_group_marketing_opt_in: state.formFields.get('agreedToVenueGroupMarketingOptIn'),
    })
  }
  if (state.formFields.get('displayVenueSpecificMarketingOptInPolicy')) {
    _.extend(userData, {
      venue_specific_marketing_opt_in: state.formFields.get('agreedToVenueSpecificMarketingOptIn'),
    })
  }
  if (state.formFields.get('displayVenueSmsMarketingOptInPolicy')) {
    _.extend(userData, {
      venue_sms_marketing_opt_in: state.formFields.get('agreedToVenueSmsMarketingOptIn'),
    })
  }
  if (state.formFields.get('displayVenueGroupMarketingOptInPolicy') || state.formFields.get('displayVenueSpecificMarketingOptInPolicy')) {
    _.extend(userData, {
      marketing_opt_in:
        state.formFields.get('agreedToVenueGroupMarketingOptIn') || state.formFields.get('agreedToVenueSpecificMarketingOptIn'),
    })
  }
  if (state.formFields.get('displayReservationSmsOptInPolicy')) {
    _.extend(userData, {
      reservation_sms_opt_in: state.formFields.get('agreedToReservationSmsOptIn'),
    })
  }
  if (state.formFields.get('displayTailoredCommunicationOptInPolicy')) {
    _.extend(userData, {
      tailored_communication_opt_in: state.formFields.get('agreedToTailoredCommunicationOptIn'),
    })
  }
  if (state.formFields.get('displayAboveAgeConsentPolicy')) {
    _.extend(userData, {
      agreed_to_above_age_consent: state.formFields.get('agreedToAboveAgeConsentOn'),
    })
  }
  if (state.formFields.get('isDisplayCustomCheckoutPolicy')) {
    _.extend(userData, {
      agreed_custom_checkout_policy: state.formFields.get('agreedCustomCheckoutPolicy'),
    })
  }
  if (state.formFields.get('displayAgreedToDietaryOptIn')) {
    _.extend(userData, {
      agreed_to_gdpr_dietary_optin: state.formFields.get('agreedToDietaryGdprOptinOn'),
    })
  }
  return userData
}

export const prepCaptchaData = state => ({
  recaptcha: state.formFields.get('recaptcha'),
})

export const prepTags = tags => {
  let clientTags = []
  let reservationTags = []
  _.each(tags.tagGroups, group => {
    const tagArr = []
    _.each(group.selectedTags, val => tagArr.push([group.privacy, group.id, group.name, val].join('##')))
    if (group.domain === 'ReservationActual') {
      reservationTags = reservationTags.concat(tagArr)
    } else if (group.domain === 'VenueGroupClient') {
      clientTags = clientTags.concat(tagArr)
    }
  })

  return { client_tags: clientTags, reservation_tags: reservationTags }
}

export const prepResData = (selectedTimeSlot, accessPersistentId, partySize, state) => {
  const dateStr = selectedTimeSlot.format('Y-MM-DD')
  const timeStr = selectedTimeSlot.format('h:mm A')
  const timeSlotVenue = state.searchResults.get('timeSlotVenue')
  const venueId = state.venueEntities[timeSlotVenue].id
  const shiftPersistentId = state.availabilityLookup.getIn([timeSlotVenue, dateStr, timeStr, accessPersistentId, 'shiftPersistentId'])
  const selectedUpsells = upsellsSelectors.selectUpsellRequestData(state)
  const reservationHoldId = state.reservationHold.get('reservationHoldId')
  const promoCodeId = state.formFields.get('validPromoCode')
  return {
    shift_persistent_id: shiftPersistentId,
    access_persistent_id: accessPersistentId,
    date: selectedTimeSlot.format('MM-DD-Y'),
    time: selectedTimeSlot.format('h:mm a'),
    party_size: partySize,
    venue_id: venueId,
    selected_upsells: JSON.stringify(selectedUpsells),
    campaign: state.app.requestParams.campaign,
    reservation_hold_id: reservationHoldId,
    promo_code_id: promoCodeId,
    client_token: state.clientInfo.id,
    ...pickedDurationData(state),
  }
}

const pickedDurationData = state => (state.search.get('enableDurationPicker') ? { picked_duration: state.search.get('duration') } : {})

export const prepChargeData = (state, cardToken = null, cardData = null, isStripePaymentElement = false) => {
  const formFields = state.formFields.toJS()
  const pricingData = selectPricingData(state)
  const { ccPaymentRule } = selectTimeSlotEntity(state)
  const encryptor = new CyberEncrypt()
  if (pricingData.amountDue || ccPaymentRule === creditCardPaymentRuleTypes.SAVE_FOR_LATER) {
    // eslint-disable-next-line no-param-reassign
    cardData = cardData
      ? JSON.stringify(cardData)
      : encryptor.encrypt([formFields.cardNum, formFields.cardMonthExp, formFields.cardYearExp, formFields.cardCvv].join('|'))
  } else {
    // eslint-disable-next-line no-param-reassign
    cardData = null
  }

  let resultData = {
    card_token: cardToken,
    encryption_id: state.widgetSettings.encryptionId,
    charge_base_amount: pricingData.baseAmount,
    charge_upsell_amount: pricingData.upsellBaseAmount,
    promo_discount_amount: pricingData.promoCodeDiscount,
    gratuity_rate: formFields.gratuityCharge,
    selected_gratuity_rate: formFields.selectedGratuityCharge,
    charge_gratuity: pricingData.paidInGratuity + pricingData.paidInSelectedGratuity,
    charge_service_charge: pricingData.paidInServiceCharge,
    charge_tax: pricingData.paidInTax,
    charge_amount: pricingData.finalAmount,
    amount_due: pricingData.amountDue,
    card_data: cardData,
    zip: formFields.zipCode,
    card_first_name: formFields.cardFirstName,
    card_last_name: formFields.cardLastName,
    redemption_encryption_id: state.widgetSettings.redemptionCardEncryptionId,
    email: formFields.email,
    address1: formFields.address1,
    city: formFields.city,
    locality: formFields.locality,
    administrativeArea: formFields.administrativeArea,
    countryCode: formFields.countryCode,
    postalCode: formFields.postalCode,
    is_stripe_payment_element: isStripePaymentElement,
    browser_screen_width: Number.isNaN(window.innerWidth) ? window.clientWidth : window.innerWidth,
    browser_screen_height: Number.isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight,
  }

  const redemptions = state.redemption && state.redemption.redemptions
  const aesEncryptor = new AESEncrypt(state.widgetSettings.redemptionCardEncryptionKey)

  if (_.size(redemptions)) {
    const redemptionData = _.map(redemptions, redemption => {
      if (!redemption) {
        return undefined
      }

      const { redemptionCardId, redemptionCardPin, amountToRedeem } = redemption
      const redemptionCardFields = [redemptionCardId, redemptionCardPin].join('|')
      const encryptedCardFields = aesEncryptor.encrypt(redemptionCardFields).split('+').join('-')
      return {
        redemption_card_fields: encryptedCardFields,
        redemption_amount: amountToRedeem,
      }
    })

    resultData = {
      ...resultData,
      redemption_data: JSON.stringify(redemptionData),
      amount_due: pricingData.amountDue,
      redemption_system: 'VALUTEC',
    }
  }

  return resultData
}

export const prepUserRequestData = state => ({
  first_name: state.formFields.get('firstName'),
  last_name: state.formFields.get('lastName'),
  email: state.formFields.get('email'),
  phone_number: state.formFields.get('phoneNumber'),
  country_code: state.formFields.get('phoneNumberLocale'),
  client_request: state.formFields.get('note'),
  request_sms_opt_in: state.formFields.get('consentToNotifications'),
  preferred_language_code: state.languages.clientPreferredLanguage || state.languages.selectedLanguage,
})

export const prepRequestData = state => {
  const venueKey = state.search.get('selectedVenue')
  const { venueId } = state.venueEntities[venueKey]

  const { priorityAlertsEnabled, priorityAlertsPartySizeMin, priorityAlertsPartySizeMax } = state.venueInfo

  const partySize = state.search.get('partySize')
  const experienceId = state.experience.get('id')
  const isHavingEndTime =
    !experienceId && priorityAlertsEnabled && priorityAlertsPartySizeMin <= partySize && partySize <= priorityAlertsPartySizeMax

  return {
    date: state.search.get('dateMoment').format('Y-MM-DD'),
    est_arrival_time: moment(state.searchResults.get('selectedRequest')).format('h:mm A'),
    end_time: isHavingEndTime ? moment(state.searchResults.get('selectedEndTimeRequest')).format('h:mm A') : false,
    party_size: partySize,
    venue_id: venueId,
    request_class: 'table',
    preferred_messaging_channel: state.venueInfo.isWhatsappEnabled
      ? state.formFields.get('preferredMessagingChannel', state.venueInfo.defaultMessageChannel)
      : undefined,
  }
}

export const prepHoldReservationData = (selectedTimeSlot, accessPersistentId, shiftPersistentId, state) => {
  const { partySize } = state.search.toJS()
  return {
    date: selectedTimeSlot.format('YYYY-MM-DD'),
    time: selectedTimeSlot.format('h:mm a'),
    shift_persistent_id: shiftPersistentId,
    access_persistent_id: accessPersistentId,
    party_size: partySize,
    ...(state.modifyReservation.get('isModifyResMode') && {
      actual_id: state.modifyReservation.get('actualId'),
    }),
    ...pickedDurationData(state),
  }
}

export const prepReleaseReservationHoldData = state => {
  const { shiftPersistentId, selectedTimeSlot, reservationHoldId } = state.reservationHold.toJS()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dateStr = selectedTimeSlot.format('Y-MM-DD')
  return {
    date: selectedTimeSlot.format('MM-DD-Y'),
    shift_persistent_id: shiftPersistentId,
    reservation_hold_id: reservationHoldId,
  }
}

export const prepWaitlistData = state => {
  const waitlistData = {
    venue_id: state.venueInfo.urlKey,
    first_name: state.formFields.get('firstName'),
    last_name: state.formFields.get('lastName'),
    email: state.formFields.get('email'),
    notes: state.formFields.get('note'),
    phone_number: state.formFields.get('phoneNumber'),
    phone_locale: state.formFields.get('phoneNumberLocale'),
    party_size: state.waitlist.get('party_size'),
    max_wait_time: state.waitlist.get('quoted_wait_time').toString(),
    shift_persistent_id: state.waitlist.get('shiftPersistentId'),
    access_persistent_id: state.waitlist.get('chosenAccessRuleId'),
    selected_language: state.languages.selectedLanguage,
    agreed_to_reservation_sms_opt_in: state.formFields.get('agreedToReservationSmsOptIn', false),
  }
  if (state.venueInfo.isWhatsappEnabled) {
    _.extend(waitlistData, {
      preferred_messaging_channel: state.formFields.get('preferredMessagingChannel', state.venueInfo.defaultMessageChannel),
    })
  }
  if (state.formFields.get('displayVenueSpecificMarketingOptInPolicy')) {
    _.extend(waitlistData, {
      venue_marketing_opt_in: state.formFields.get('agreedToVenueSpecificMarketingOptIn'),
    })
  }
  if (state.formFields.get('displayVenueGroupMarketingOptInPolicy')) {
    _.extend(waitlistData, {
      venue_group_marketing_opt_in: state.formFields.get('agreedToVenueGroupMarketingOptIn'),
    })
  }
  if (state.widgetSettings.venueSmsMarketingOn) {
    _.extend(waitlistData, {
      venue_sms_marketing_opt_in: state.formFields.get('agreedToVenueSmsMarketingOptIn'),
    })
  }
  if (state.widgetSettings.tailoredCommunicationOn) {
    _.extend(waitlistData, {
      tailored_communication_opt_in: state.formFields.get('agreedToTailoredCommunicationOptIn'),
    })
  }
  return waitlistData
}

export const removeCountryCodeFromPhone = (phoneNumber, dialCode) => _.replace(phoneNumber, `+${dialCode}`, '')

export const calculateSelectedUpsells = (selectedAutomaticUpsells, chargeType, partySize, upsellInventories, duration) => {
  let automaticUpsellCost = Object.values(selectedAutomaticUpsells)
    .filter(selectedUpsell => selectedUpsell?.id in upsellInventories)
    .reduce((acc, selectedUpsell) => {
      const quantityNum = selectedUpsell.quantityEqualType === 'SPECIFIC_NUMBER' ? selectedUpsell.quantityNum : partySize
      const upsellInventoryCost = upsellInventories[selectedUpsell.id].price * quantityNum
      // eslint-disable-next-line no-param-reassign
      selectedUpsell.upsellPrice = upsellInventories[selectedUpsell.id].price
      // eslint-disable-next-line no-param-reassign
      selectedUpsell.quantity = quantityNum
      return acc + upsellInventoryCost
    }, 0)
  if (chargeType) {
    if (chargeType.startsWith('person')) {
      automaticUpsellCost /= partySize
    }
    if (duration && chargeType.endsWith('slot')) {
      automaticUpsellCost /= duration / 15
    }
  }
  return automaticUpsellCost
}

export const SEARCHRESULTS_DISPLAY_LENGTH = 30

export const ALL_LOCATIONS = 'alllocations'
