import { Map } from 'immutable'
import { SELECT_EVENT } from '../actions/ActionTypes'

const selectedEvent = (state = Map(), action) => {
  switch (action.type) {
    case SELECT_EVENT:
      return state.merge({
        date: action.date,
        eventId: action.eventId,
      })
    default:
      return state
  }
}

export default selectedEvent
