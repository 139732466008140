/* eslint react/prop-types: 0,  jsx-a11y/no-static-element-interactions: 0 */
import React from 'react'
import * as styles from '../assets/styles/checkout'

const CheckoutSpinner = ({ status, mediaUrl, error, dismissSpinner }) => {
  const spinnerUrl = `${mediaUrl}images/SevenRoomsLoader.svg`
  let spinnerBody
  if (status === 'processing') {
    spinnerBody = (
      <div style={styles.spinnerBody}>
        <span style={styles.spinnerText}>Processing Checkout</span>
        <img alt="sevenrooms-spinner" style={styles.spinnerGif} src={spinnerUrl} />
      </div>
    )
  } else if (status === 'failure') {
    spinnerBody = (
      <div style={styles.spinnerBody}>
        <span style={styles.spinnerTextFailure}>{error || 'Unable to process request'}</span>
        <button id="checkout-error-modal" style={styles.okButton} onClick={dismissSpinner} tabIndex="0">
          Ok
        </button>
      </div>
    )
  } else if (status === 'reload') {
    spinnerBody = (
      <div style={styles.spinnerBody}>
        <span style={styles.spinnerTextFailure}>{error || 'Unable to process request'}</span>
        <button id="checkout-error-modal" style={styles.okButton} onClick={dismissSpinner} tabIndex="0">
          Reload
        </button>
      </div>
    )
  }

  return (
    <div style={styles.spinnerCover} className="sr-spinner-popover">
      <div style={styles.spinnerWrapper}>{spinnerBody}</div>
    </div>
  )
}

export default CheckoutSpinner
