import { useEffect, type ReactElement } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { DateTime, TimeOnly } from '@sevenrooms/core/timepiece'
import { BasicButton, IconButton } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex, HStack, Root, Tooltip, VStack } from '@sevenrooms/core/ui-kit/layout'
import { gxTheme, type ThemeColor } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { hexToRgb } from '../utils/color'
import type { FloorPlanState } from './interactiveFloorplanReducer'
import type { EventData, VenueInfo } from './types'

const HEADER_HEIGHT = '70px'

interface InteractiveFloorplanContainerProps {
  deviceSize: 'large' | 'small'
  dismiss: () => void
  eventData: EventData
  eventDetailsComponent: ReactElement
  floorplanComponent: ReactElement
  inventoryComponent: ReactElement
  backgroundColor: ThemeColor
  selectedDate: string
  textColor: ThemeColor
  venueInfo: VenueInfo
  layoutHeight?: number
  pageBackgroundColor: string
}

function InteractiveFloorplanContainer({
  deviceSize,
  dismiss,
  eventData,
  eventDetailsComponent,
  floorplanComponent,
  inventoryComponent,
  backgroundColor,
  selectedDate,
  textColor,
  venueInfo,
  layoutHeight,
  pageBackgroundColor,
}: InteractiveFloorplanContainerProps) {
  const isDesktopLayout = deviceSize === 'large'
  const backgroundColorKey = 'backgroundColor' as ThemeColor
  const textColorKey = 'textColor' as ThemeColor
  const eventDate = DateTime.from(selectedDate).toDateOnly().formatNYearSMonthNDay()
  const eventStartTime = eventData.eventStartTime ? TimeOnly.from(eventData.eventStartTime).formatSTime() : ''
  const eventEndTime = eventData.eventEndTime ? TimeOnly.from(eventData.eventEndTime).formatSTime() : ''

  useEffect(() => {
    const templateStylesElem = isDesktopLayout && document.getElementById('templateStyles')
    const parentTable = document.getElementById('bw-table')

    if (templateStylesElem) {
      document.head.removeChild(templateStylesElem)
      if (parentTable) {
        parentTable.style.width = '100vw'
        parentTable.style.height = '100vh'
        parentTable.style.backgroundColor = pageBackgroundColor
      }
    }
    return () => {
      if (templateStylesElem) {
        if (parentTable) {
          parentTable.style.width = ''
          parentTable.style.height = ''
          parentTable.style.backgroundColor = ''
        }
        document.head.appendChild(templateStylesElem)
      }
    }
  }, [isDesktopLayout, pageBackgroundColor])

  const header = (
    <>
      <HStack alignItems="center">
        <IconButton size="s" borderType="round" icon="GX-back" onClick={dismiss} iconBackgroundColor="lightFont" />
      </HStack>
      <HStack flex="1 1 auto" alignItems="center" justifyContent="center">
        <div>
          <StyledDateTimeWrapper textAlign="center">
            <Text fontSize="xl" color={textColorKey}>
              {eventDate}
            </Text>
          </StyledDateTimeWrapper>
          <StyledDateTimeWrapper textAlign="center">
            {eventStartTime && eventEndTime && (
              <Text fontSize="xs" color={textColorKey}>
                {eventStartTime} - {eventEndTime}
              </Text>
            )}
          </StyledDateTimeWrapper>
        </div>
      </HStack>
      <HStack alignItems="center">
        <Tooltip
          title={venueInfo.name}
          content={
            <VStack spacing="xs">
              <Text fontSize="l" color="lightFont">
                {eventData.name}
              </Text>
              <Text fontSize="s" color="lightFont">
                {eventDate}
              </Text>
              {eventStartTime && eventEndTime && (
                <Text fontSize="xs" color="lightFont">
                  {eventStartTime} - {eventEndTime}
                </Text>
              )}
            </VStack>
          }
        >
          <BasicButton aria-label="Additional Information">
            <Icon size="lg" name="GX-info-outline" color={textColorKey} />
          </BasicButton>
        </Tooltip>
      </HStack>
      <Box />
    </>
  )

  const desktopLayout = (
    <StyledCenteredVerticallyOnScreen width="100vw">
      <Flex justifyContent="center" height={layoutHeight}>
        <Flex display="block" flex="0 0 330px" overflowY="auto" borderRightColor="borders" backgroundColor={backgroundColorKey}>
          <HStack
            backgroundColor={backgroundColorKey}
            borderBottomColor="borders"
            p="s"
            position="sticky"
            spacing="s"
            top="none"
            zIndex={1}
            height={HEADER_HEIGHT}
          >
            {header}
          </HStack>
          {inventoryComponent}
          {eventDetailsComponent}
        </Flex>
        <StyledFlexWithNoScrollbar
          display="block"
          flex="0 1 auto"
          minWidth="200px"
          maxWidth="1024px"
          overflow="auto"
          backgroundColor={backgroundColorKey}
        >
          {floorplanComponent}
        </StyledFlexWithNoScrollbar>
      </Flex>
    </StyledCenteredVerticallyOnScreen>
  )

  const mobileLayout = (
    <Box width="100vw" position="absolute" left="none">
      <Box backgroundColor={backgroundColorKey}>
        <HStack p="s" spacing="s" borderBottomColor="borders" height={HEADER_HEIGHT}>
          {header}
        </HStack>
        <Box height={`calc(50vh - ${HEADER_HEIGHT})`} overflow="auto">
          {floorplanComponent}
        </Box>
        <Box height="50vh" overflowY="auto" borderTopColor="borders">
          {inventoryComponent}
          {eventDetailsComponent}
        </Box>
      </Box>
    </Box>
  )

  const themeOverride = {
    colors: {
      ...gxTheme.colors,
      backgroundColor,
      textColor,
    },
  }

  return (
    <Root theme="gx" themeOverride={themeOverride}>
      {isDesktopLayout ? desktopLayout : mobileLayout}
    </Root>
  )
}

const mapStateToProps = (state: {
  resize: { height?: number }
  interactiveFloorplan: FloorPlanState
  widgetSettings: { colorBackground?: string; colorBackgroundOpacity?: string }
}) => {
  const floorplanHeight = state.interactiveFloorplan.height
  const windowHeight = state.resize.height
  let layoutHeight
  if (floorplanHeight && windowHeight && floorplanHeight < windowHeight) {
    layoutHeight = floorplanHeight
  } else {
    layoutHeight = windowHeight
  }

  let pageBackgroundColor = ''
  const pageBackgroundColorSetting = state.widgetSettings.colorBackground
  const pageBackgroundColorOpacitySetting = state.widgetSettings.colorBackgroundOpacity
  if (pageBackgroundColorSetting && pageBackgroundColorOpacitySetting) {
    const rgba = hexToRgb(pageBackgroundColorSetting, pageBackgroundColorOpacitySetting)
    pageBackgroundColor = rgba ?? ''
  }

  return {
    layoutHeight,
    pageBackgroundColor,
  }
}

export default connect(mapStateToProps)(InteractiveFloorplanContainer)

const StyledFlexWithNoScrollbar = styled(Flex)`
  ::-webkit-scrollbar {
    display: none;
  }
`

const StyledCenteredVerticallyOnScreen = styled(Box)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

// Reset the font-size set by #bw-wrapper
const StyledDateTimeWrapper = styled(Box)`
  font-size: initial;
`
