import * as styles from '../../assets/styles/eventOverview'
import QuantityStepper from '../../containers/QuantityStepper'
import { connect } from 'react-redux'
import { incrementItem, selectInventoryItems } from '../../actions/navigation'
import ContinueButton from './ContinueButton'
import { parseBoolean } from '../../utils/preloadedState'
import { calcTotalFeePrice } from '../../utils/calcCharges'
import { RESERVATION_TYPE, GUESTLIST_FREE_TYPE, REQUEST_TYPE } from '../../reducers/inventoryReducer'

const { fonts_color_primary, fonts_color_secondary } = widgetInit.settings

const InventoryItem = ({
  inventoryItem,
  inventoryAvailabilities,
  inventoryCart,
  languageCode,
  currencyCode,
  isDisabled,
  textMinimumSpend,
  isFeesInPriceDisplayed,
  incrementItem,
  selectInventoryItem,
  selectedDate,
  selectedEventId,
  defaultServiceCharge,
  deviceSize,
}) => {
  const { price, compText, inventoryId, inventoryType } = inventoryItem
  const { availabilityId } = inventoryAvailabilities[inventoryId]
  const { quantity } = inventoryCart.find(item => item.inventoryId === inventoryId)
  const maxQuantity = Math.min(inventoryItem.maxQuantity, inventoryAvailabilities[inventoryId].remainingQuantity)

  const totalFeePrice = calcTotalFeePrice(inventoryItem, 1, price, defaultServiceCharge)
  const totalPrice = isFeesInPriceDisplayed ? price + totalFeePrice : price
  const currencyFormatter = new Intl.NumberFormat(languageCode, { style: 'currency', currency: currencyCode })

  const opacity = isDisabled ? '0.3' : '1'
  const isSmallResolutionDevice = deviceSize === 'small'

  const getHeaderText = (inventory, quantity) => {
    let headerText = ''
    const numGuests = quantity === 0 ? inventory.entryPerReservation : quantity * inventory.entryPerReservation

    if (numGuests <= 1) {
      headerText = 'For 1 guest'
    } else {
      headerText = `For ${numGuests} guests`
    }

    if (inventory.minQuantity > 1) {
      headerText = `${headerText}, ${inventory.minQuantity} tickets minimum`
    }

    if (inventory.inventoryType === RESERVATION_TYPE && inventory.minPrice) {
      const minDollarText = (function () {
        const minDollarAmount = quantity === 0 ? inventory.minPrice : quantity * inventory.minPrice
        const minDollarAmountFormatted = currencyFormatter.format(minDollarAmount)
        return `, ${minDollarAmountFormatted} ${textMinimumSpend}`
      })()
      headerText = `${headerText}${minDollarText}`
    }

    return headerText
  }

  if (inventoryType === REQUEST_TYPE) {
    return (
      <div style={{ backgroundColor: isDisabled ? styles.rgbaWidgetBackground : styles.rgbaCellBackground, padding: '20px 16px' }}>
        <div
          style={{
            position: 'absolute',
            fontSize: '12px',
            fontWeight: '300',
            color: fonts_color_secondary,
            marginTop: '-7px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '506px',
            opacity,
          }}
          data-test="header-text"
        >
          Request only
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              fontSize: '18px',
              color: fonts_color_primary,
              flex: '1 1 auto',
              opacity,
            }}
          >
            {inventoryItem.inventoryName}
          </div>
          <ContinueButton
            aria-label="continue to checkout"
            disabled={isDisabled}
            onClick={() => {
              incrementItem(selectedDate, selectedEventId, inventoryId)
              selectInventoryItem(inventoryId, availabilityId)
            }}
          >
            Request
          </ContinueButton>
        </div>
      </div>
    )
  }

  return (
    <div style={{ backgroundColor: isDisabled ? styles.rgbaWidgetBackground : styles.rgbaCellBackground, padding: '20px 16px' }}>
      <div
        style={{
          position: 'absolute',
          fontSize: '12px',
          fontWeight: '300',
          color: fonts_color_secondary,
          marginTop: '-10px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '506px',
          opacity,
        }}
        data-test="header-text"
      >
        {getHeaderText(inventoryItem, quantity)}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            fontSize: isSmallResolutionDevice ? '16px' : '18px',
            color: fonts_color_primary,
            flex: isSmallResolutionDevice ? '0 0 50%' : '0 0 45%',
            opacity,
            paddingRight: '10px',
          }}
        >
          {inventoryItem.inventoryName}
        </div>
        <div
          style={{
            flex: isSmallResolutionDevice ? '0 0 50%' : '0 0 55%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: isSmallResolutionDevice ? '0' : '148px',
          }}
        >
          <QuantityStepper
            inventoryId={inventoryId}
            quantity={quantity}
            minQuantity={0}
            maxQuantity={maxQuantity}
            isDisabled={isDisabled}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end', opacity }}>
            <div style={{ fontSize: '16px', color: fonts_color_primary, marginLeft: '8px' }} data-test="price-text">
              {inventoryType === GUESTLIST_FREE_TYPE ? compText : currencyFormatter.format(totalPrice)}
            </div>
            {!!(isFeesInPriceDisplayed && totalFeePrice) && (
              <div
                data-test="fees-in-price"
                style={{
                  position: 'absolute',
                  fontSize: '12px',
                  fontWeight: '300',
                  color: fonts_color_secondary,
                  marginTop: '30px',
                }}
              >
                * Includes {currencyFormatter.format(totalFeePrice)} in fees
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  incrementItem: (date, eventId, inventoryId) => {
    dispatch(incrementItem(date, eventId, inventoryId))
  },
  selectInventoryItem: (inventoryId, availabilityId) => {
    dispatch(selectInventoryItems([{ inventoryId, availabilityId }]))
  },
})
const mapStateToProps = state => ({
  languageCode: state.app.language,
  textMinimumSpend: state.widgetSettings.textMinimumSpend,
  inventoryCart: state.inventoryCart.cart,
  selectedDate: state.userSelection.get('date'),
  selectedEventId: state.userSelection.get('eventId'),
  isFeesInPriceDisplayed: parseBoolean(state.widgetSettings.isFeesInPriceDisplayed),
  defaultServiceCharge: state.venueInfo.venueDefaultServiceCharge,
})
export default connect(mapStateToProps, mapDispatchToProps)(InventoryItem)
