// eslint-disable-next-line no-warning-comments
// TODO: replace this entire thing with native DateTime.
// won't be able to sell into NON-US venues until we do that. Doh!
import _ from 'lodash'
import moment from 'moment-timezone'

export const daysOfTheWeekShort = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
export const daysOfTheWeekLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate()

export const firstDateOfMonth = (month, year) => new Date(year, month, 1).getDay()

export const numRowsInMonth = (month, year) => {
  const firstOfMonth = new Date(year, month - 1, 1)
  const lastOfMonth = new Date(year, month, 0)
  const used = firstOfMonth.getDay() + lastOfMonth.getDate()
  return Math.ceil(used / 7)
}

export const getDayName = (format, year, month, day) => {
  if (format === 'short') {
    return daysOfTheWeekShort[new Date(year, month, day).getDay()]
  }
  return daysOfTheWeekLong[new Date(year, month, day).getDay()]
}

export const getMonthName = month => monthNames[month]

// Expects Date objects
export const dateCompare = (dateA, dateB) => {
  if (dateA > dateB) {
    return -1
  } else if (dateB > dateA) {
    return 1
  }
  return 0
}

export const padInt = num => (num <= 9 ? `0${num}` : `${num}`)

export const getStrFromDate = date => `${date.getFullYear()}-${padInt(date.getMonth() + 1)}-${padInt(date.getDate())}`

export const getFormattedTime = fourDigitTime => {
  const hours24 = parseInt(fourDigitTime.substring(0, 2), 10)
  const hours = ((hours24 + 11) % 12) + 1
  const amPm = hours24 > 11 ? 'PM' : 'AM'
  const minutes = fourDigitTime.substring(3, 5)
  return `${hours}:${minutes} ${amPm}`
}

const _titleCase = str =>
  str
    .toLowerCase()
    .split(' ')
    .map(i => i[0].toUpperCase() + i.substring(1))
    .join(' ')

export const getDateFormattedMedium = date => {
  // Fri, June 3, 2016
  const month = parseInt(date.getMonth(), 10)
  const dayName = _titleCase(getDayName('short', date.getFullYear(), date.getMonth(), date.getDate()))
  const monthName = getMonthName(month)
  return [dayName, `${monthName} ${month + 1}`, date.getFullYear()].join(', ')
}

export const getDateFormatMedium = dateStr => {
  const [year, month, day] = dateStr.split('-')
  const dayName = _.capitalize(getDayName('short', year, month - 1, day).toLowerCase())
  const monthName = getMonthName(month - 1)
  return `${dayName}, ${monthName} ${Number(day)}, ${year}`
}

export const getDateFormattedShort = dateStr => {
  const [year, month, day] = dateStr.split('-')
  return `${Number(month)}/${Number(day)}/${year}`
}

const _getTimeStr = (timeMoment, language) =>
  language.toUpperCase() === 'EN-US' ? timeMoment.format('h:mm A') : timeMoment.format('HH:mm')

export const getDateTimeIntervals = (startTime, endTime, language) => {
  const startTimeMoment = moment(startTime, 'hh:mm:ss')
  const endTimeMoment = moment(endTime, 'hh:mm:ss')
  const timeIter = startTimeMoment.clone()
  if (startTimeMoment.isSameOrAfter(endTimeMoment)) {
    endTimeMoment.add(1, 'day')
  }
  const valueSet = [_getTimeStr(timeIter, language)]
  timeIter.add(15, 'minutes')
  while (timeIter <= endTimeMoment) {
    valueSet.push(_getTimeStr(timeIter, language))
    timeIter.add(15, 'minutes')
  }
  return valueSet
}

export const getLocaleDateFormat = locale => {
  const defaultLocaleDateFormat = 'mm/dd'
  const format = moment.localeData(locale).longDateFormat('L').toLowerCase() // e.g. dd/mm/yyyy

  const monthPos = format.indexOf('m')
  const dayPos = format.indexOf('d')

  if (monthPos !== -1 && dayPos !== -1) {
    return monthPos < dayPos ? 'mm/dd' : 'dd/mm'
  }

  return defaultLocaleDateFormat
}

export const adjustBirthdayString = value => {
  if (!value) {
    return ''
  }
  if (value.length === 1) {
    return `0${value}`
  }
  return value
}
