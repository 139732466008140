import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import * as styles from '../assets/styles/eventOverview'
import InventoryItemLarge from './InventoryItemLarge'
import InventoryItemSmall from './InventoryItemSmall'

const InventoryList = ({
  inventoryData,
  inventoryEdits,
  deviceSize,
  boundToggleInventoryItem,
  selectInventoryItem,
  isPrivate,
  isBookable,
  isEventOpened,
  date,
  currencyCode,
  tieredEvents,
  isExperienceMode,
  isFloorplan,
  isFeesInPriceDisplayed,
  defaultServiceCharge,
}) => {
  const renderNotAvailableMessage = message => (
    <div
      style={{
        ...styles.inventoryListItem,
        ...styles.largeDevicePadding,
      }}
    >
      <div style={styles.noAvailText}>{message}</div>
    </div>
  )

  const mapInventoryToList = () => {
    if (!isEventOpened && _.isEmpty(inventoryData)) {
      let msg = 'Booking for this event has not been opened yet, please check back at a later date.'
      if (date) {
        if (date.indexOf('T') !== -1) {
          const openDate = moment(date).format('MMMM D, YYYY')
          msg = `Booking for this event opens on ${openDate}.`
        }
      }
      return renderNotAvailableMessage(msg)
    }

    if (_.isEmpty(inventoryData) || (!isExperienceMode && isPrivate && !isBookable)) {
      return renderNotAvailableMessage('This event is sold out.')
    }

    return _.map(_.sortBy(inventoryData, 'viewOrder'), inventoryItem => {
      if (_.isArray(tieredEvents) && tieredEvents.indexOf(inventoryItem.inventoryId) >= 0) {
        return null
      }
      const { inventoryId, inventoryType } = inventoryItem
      const inventoryEdit = inventoryEdits && inventoryEdits[inventoryId]
      const remainingQuantity = Math.min(
        inventoryItem.maxQuantity,
        inventoryEdit ? inventoryEdit.remainingQuantity : inventoryItem.maxQuantity
      )

      if (remainingQuantity <= 0 && inventoryType !== 'REQUEST') {
        return null
      }

      if (deviceSize === 'large') {
        return (
          <div key={inventoryItem.id}>
            <InventoryItemLarge
              deviceSize={deviceSize}
              inventoryItem={inventoryItem}
              inventoryEdits={inventoryEdits}
              selectInventoryItem={selectInventoryItem}
              currencyCode={currencyCode}
              isFeesInPriceDisplayed={isFeesInPriceDisplayed}
              defaultServiceCharge={defaultServiceCharge}
            />
            <hr style={styles.inventoryItemSeperator} />
          </div>
        )
      }
      return (
        <div key={inventoryItem.id}>
          <InventoryItemSmall
            deviceSize={deviceSize}
            inventoryItem={inventoryItem}
            inventoryEdits={inventoryEdits}
            boundToggleInventoryItem={boundToggleInventoryItem}
            selectInventoryItem={selectInventoryItem}
            currencyCode={currencyCode}
            isFloorplan={isFloorplan}
            isFeesInPriceDisplayed={isFeesInPriceDisplayed}
            defaultServiceCharge={defaultServiceCharge}
          />
          <hr style={styles.inventoryItemSeperator} />
        </div>
      )
    })
  }

  return <div style={styles.inventoryWrapper}>{mapInventoryToList()}</div>
}

export default InventoryList
