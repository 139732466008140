import _ from 'lodash'
import moment from 'moment-timezone'
import { Component } from 'react'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import styled, { createGlobalStyle } from 'styled-components'
import { AccessibleDivComponent } from 'svr/common/A11y'
import AdyenForm from 'svr/component-lib/Widget/Payments/Adyen/AdyenForm'
import SaferpayForm from 'svr/component-lib/Widget/Payments/SaferPay/SaferpayForm'
import { getSaferpayToken } from 'svr/component-lib/Widget/Payments/SaferPay/services'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import {
  createStripeIntent,
  postCheckoutFailure,
  postCheckoutSuccess,
  setFreedompayVisible,
  submitCheckoutData,
  submitCheckoutDataNoPayment,
  submitPaymentCheckoutDataCybersourceThreeDSRedux,
  submitRequest,
  toggleCheckoutErrorModal,
  tryPostCheckout,
} from 'widget/events/actions/services'
import { selectLanguageStrings } from 'widget/events/selectors/languageSelectors'
import { changeFormField } from 'widget/paylink/actions/forms'
import GMapsAddress from 'widget/universal/components/GMapsAddress'
import { changeCaptchaField, checkPromoCodeForCheckout, completeCaptchaField, mandatePolicies, validateAllFields } from '../actions/forms'
import {
  clearErrorScrolling,
  dismissSpinner,
  initErrorScrollAnimation,
  toggleBookingPolicyDisplay,
  togglePromoCodeErrorDisplay,
  toggleReservationSmsOptInPolicyDisplay,
  toggleSpecialAttentionMessage,
  toggleTailoredCommunicationOptInDisplay,
  toggleVenueGroupMarketingOptInPolicyDisplay,
  toggleVenueSmsMarketingOptInPolicyDisplay,
  toggleVenueSpecificMarketingOptInPolicyDisplay,
} from '../actions/navigation'
import * as styles from '../assets/styles/checkout'
import * as eventStyles from '../assets/styles/eventList'
import CheckoutSpinner from '../components/CheckoutSpinner'
import PolicyPopover from '../components/PolicyPopover'
import * as CheckoutPolicySelector from '../selectors/CheckoutPolicySelector'
import CheckoutCybersourceThreeDsPayment from './CheckoutCybersourceThreeDsPayment'
import CheckoutInformation from './CheckoutInformation'
import CheckoutPayment from './CheckoutPayment'
import CheckoutPaymentFreedompay from './CheckoutPaymentFreedompay'
import CheckoutPaymentStripe from './CheckoutPaymentStripe'
import CheckoutSummary from './CheckoutSummary'
import Shift4FormCheckout from 'svr/component-lib/Widget/Payments/Shift4/Shift4Form'
import Analytics from '../services/AnalyticsServices'
import { parseBoolean } from 'widget/events/utils/preloadedState'
import { calculateSelectedInventoryPrices, getSelectedInventoryCart } from '../selectors/inventoryCartSelector'
import { REQUEST_TYPE } from '../reducers/inventoryReducer'
import formFields from '../reducers/formFieldsReducer'

class Checkout extends Component {
  constructor(props) {
    super(props)
    this.inputFields = { information: {}, payment: {} }
    this.checkoutInfoId = 'sr-checkout-information'
    this.checkoutPaymentId = 'sr-checkout-payment'
    this.submitHandler = _.debounce(this.submitHandler.bind(this), 2000, { leading: true, trailing: false })
    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this)
    this.validateInputs = this.validateInputs.bind(this)
    this.onCaptchaChange = this.onCaptchaChange.bind(this)
    this.onGMapsAddressUpdate = this.onGMapsAddressUpdate.bind(this)
    this.state = {}
    this.onShift4Success = null
    if (this.props.paymentType === AccountTypes.STRIPE) {
      const options = this.props.accountId ? { stripeAccount: this.props.accountId } : {}
      this.stripe = window.Stripe(window.widgetInit.stripeKey, options)
      if (this.props.isPaymentRequired) {
        this.props.createStripeIntent()
      }
    } else if (this.props.paymentType === AccountTypes.ADYEN) {
      this.state = {
        adyen: { state: { isValid: undefined } },
        adyenPaymentMethod: undefined,
      }
    } else if (this.props.paymentType === AccountTypes.SAFERPAY) {
      this.saferpayToken = null
      this.state = { saferpayIsValid: undefined }
    } else if (this.props.paymentType === AccountTypes.SHIFT_4) {
      this.onShift4Success = (token, cardData) =>
        this.props.submitCheckoutData({ ...cardData, card_token: token }, this.props.isRequest, true)
    }
    this.state.submitClicked = false
  }

  componentDidMount() {
    Analytics.beginCheckout()
    this.props.checkPromoCodeForCheckout()
  }

  componentDidUpdate() {
    const focusEl = document.getElementById('checkout-error-modal')
    if (focusEl) {
      focusEl.focus()
    }
  }

  onCaptchaChange(value) {
    this.props.changeCaptchaField(value)
    this.props.completeCaptchaField(value)
  }

  // eslint-disable-next-line react/sort-comp
  validateInputs() {
    const validateFields = fieldObj =>
      _.reduce(
        fieldObj,
        (fieldMap, input, field) => {
          let isValid = false
          if (input && 'validate' in input) {
            isValid = !input.validate()
          } else if (input && 'isValid' in input) {
            isValid = input.isValid() !== true
          }
          return _.assign(fieldMap, isValid && { [field]: true })
        },
        {}
      )

    this.setState(prevState => ({ ...prevState, submitClicked: true }))

    const invalidInformationFields = validateFields(this.inputFields.information)
    if (
      this.props.birthdayType === 'Optional' &&
      !this.props.birthday &&
      Object.prototype.hasOwnProperty.call(invalidInformationFields, 'birthday')
    ) {
      delete invalidInformationFields.birthday
    }
    if (this.props.salutationType === 'Optional' && !this.props.salutationType && 'salutation' in invalidInformationFields) {
      delete invalidInformationFields.salutation
    }
    const invalidPaymentFields = this.props.isPaymentRequired ? validateFields(this.inputFields.payment) : {}
    const invalidTimeField =
      this.props.widgetSettings.enableFieldTime && !moment(this.props.formFields.time, ['HH:mm', 'h:mm A']).isValid() ? { time: true } : {}
    const agreedToBookingPolicyError = this.props.agreedToBookingPolicy || this.props.isRequest ? {} : { agreedToBookingPolicy: true }
    let agreedToAboveAgeConsentError = {}
    if (this.props.displayAboveAgeConsentPolicy) {
      agreedToAboveAgeConsentError = this.props.agreedToAboveAgeConsentOn || this.props.isRequest ? {} : { agreedToAboveAgeConsentOn: true }
    }
    const validReCaptcha = this.props.recaptcha || !this.props.enableRecaptcha
    const reCaptchaError = validReCaptcha ? {} : { recaptcha: true }
    const gratuityError =
      this.props.chargeGratuity && this.props.requireGratuityCharge && this.props.shouldEnableCustomGratuity && !this.props.customTip
        ? { gratuity: true }
        : {}
    this.props.completeCaptchaField(this.props.recaptcha)
    const formErrors = _.assign(
      {},
      invalidInformationFields,
      invalidPaymentFields,
      invalidTimeField,
      agreedToBookingPolicyError,
      agreedToAboveAgeConsentError,
      reCaptchaError,
      gratuityError
    )
    this.props.validateAllFields(formErrors)

    if (!_.isEmpty(invalidInformationFields)) {
      const infoElement = document.querySelector(`#${this.checkoutInfoId}`)
      infoElement.scrollIntoView({ behavior: 'smooth', block: 'end' })
      const focusEl = document.querySelector(`#${this.checkoutInfoId} input`)
      if (focusEl) {
        focusEl.focus()
      }
    } else if (!_.isEmpty(invalidPaymentFields)) {
      const paymentElement = document.querySelector(`#${this.checkoutPaymentId}`)
      paymentElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      const focusEl = document.querySelector(`#${this.checkoutPaymentId} input`)
      if (focusEl) {
        focusEl.focus()
      }
    } else if (this.props.isPaymentRequired && this.props.paymentType === AccountTypes.ADYEN && !this.state.adyen.state.isValid) {
      scroller.scrollTo(this.checkoutPaymentId, {
        duration: 200,
        smooth: true,
        offset: -40,
      })
      document.querySelector(`#${this.checkoutPaymentId} input`).focus()
    } else if (this.props.isPaymentRequired && this.props.paymentType === AccountTypes.SAFERPAY) {
      getSaferpayToken(this.saferpayToken).then(response => {
        this.saferpayToken = response.token
        this.props.submitCheckoutData({ card_token: response.token }, false, true)
      })
    } else if (formErrors.gratuity) {
      this.props.toggleCheckoutErrorModal('Please select a gratuity to continue')
    } else if (formErrors.agreedToBookingPolicy || formErrors.agreedToAboveAgeConsentOn) {
      this.props.mandatePolicies(formErrors)
    } else if (this.props.isPaymentRequired && this.isFreedomPayHpc()) {
      if (!this.props.addressCountryCode && !this.props.isFreedompayRequestOnlyPostalCode) {
        return false
      }
      this.props.setFreedompayVisible(true)
    } else if (this.props.isPaymentRequired && this.props.paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX) {
      if (!_.isEmpty(invalidPaymentFields) && this.props.isCybersourceBillingAddressRequired && !this.props.addressCountryCode) {
        scroller.scrollTo(this.checkoutPaymentId, {
          duration: 200,
          smooth: true,
          offset: -40,
        })
        document.querySelector(`#${this.checkoutPaymentId} input`).focus()
      } else {
        this.props.submitPaymentCheckoutDataCybersourceThreeDSRedux()
      }
    } else {
      return true
    }
    return false
  }

  submitHandler() {
    if (this.props.isPaymentRequired && this.props.paymentType === AccountTypes.SHIFT_4) {
      this.props.paymentEngine.submit()
    } else if (this.props.isPaymentRequired && this.props.paymentType === AccountTypes.ADYEN) {
      this.setState(prevState => ({ ...prevState, isPaymentInProgress: true }))
      if (this.state.adyenPaymentMethod === 'paypal') {
        const payPalData = { paymentMethod: { type: this.state.adyenPaymentMethod } }
        this.props.submitCheckoutData(payPalData, this.props.isRequest, this.props.isPaymentRequired, this.stripe)
      } else {
        this.state?.adyen?.dropIn?.submit()
      }
    } else {
      this.props.submitCheckoutData(this.props.formFields, this.props.isRequest, this.props.isPaymentRequired, this.stripe)
    }
  }

  onSubmitClickHandler() {
    const isDataValid = this.validateInputs()

    if (isDataValid) {
      this.submitHandler()
    }
  }

  handlerOnValidatedSaferPay = isValid => {
    this.setState({ saferpayIsValid: isValid })
  }

  handlerOnValidatedAdyen = isValid => {
    this.setState(prevState => ({
      ...prevState,
      adyen: {
        ...(prevState?.adyen || {}),
        state: {
          ...(prevState?.adyen?.state || {}),
          isValid,
        },
      },
    }))
  }

  handlerOnAdyenChangePaymentMethod = isValid => {
    this.setState({ adyenPaymentMethod: isValid })
  }

  handlerOnSubmitAdyen = adyenState => {
    try {
      this.props.submitCheckoutData(adyenState, this.props.isRequest, this.props.isPaymentRequired, this.stripe)
    } catch (error) {
      this.props.postCheckoutFailure(error)
    }
  }

  handlerOnSetCheckout = adyenState => {
    this.setState(prevState => ({ ...prevState, adyen: { ...(prevState?.adyen || {}), ...adyenState } }))
  }

  onGMapsAddressUpdate(address, place) {
    const address1 = []
    let city
    let locality
    let administrativeArea
    let postalCode
    let countryCode
    if (!place) {
      this.setState({ address: '' })
      this.props.changeFormField('address1', null)
      this.props.changeFormField('city', null)
      this.props.changeFormField('locality', null)
      this.props.changeFormField('administrativeArea', null)
      this.props.changeFormField('countryCode', null)
      this.props.changeFormField('postalCode', null)
      return
    }
    // https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
    _.each(place.address_components, addressComponent => {
      if (['street_address', 'street_number', 'route'].some(r => addressComponent.types.includes(r))) {
        address1.push(addressComponent.long_name)
      }
      if (addressComponent.types.includes('locality')) {
        locality = addressComponent.long_name
      }
      if (!locality && addressComponent.types.includes('administrative_area_level_1')) {
        locality = addressComponent.long_name
      }
      if (addressComponent.types.includes('administrative_area_level_3')) {
        city = addressComponent.long_name
      }
      if (!city && addressComponent.types.includes('administrative_area_level_2')) {
        city = addressComponent.long_name
      }
      if (addressComponent.types.includes('administrative_area_level_1')) {
        administrativeArea = addressComponent.short_name
      }
      if (addressComponent.types.includes('country')) {
        countryCode = addressComponent.short_name
      }
      if (addressComponent.types.includes('postal_code')) {
        postalCode = addressComponent.long_name
      }
    })
    this.setState({
      address,
    })

    this.props.changeFormField('address1', address1.join(' '))
    this.props.changeFormField('city', city || '')
    this.props.changeFormField('locality', locality || '')
    this.props.changeFormField('administrativeArea', administrativeArea || '')
    this.props.changeFormField('countryCode', countryCode || '')
    this.props.changeFormField('postalCode', postalCode || '')
  }

  getCheckoutSpinner() {
    return this.props.formFields.checkoutStatus === 'processing' || this.props.formFields.checkoutStatus === 'failure' ? (
      <CheckoutSpinner
        status={this.props.formFields.checkoutStatus}
        error={this.props.formFields.error}
        mediaUrl={this.props.mediaUrl}
        dismissSpinner={this.props.dismissSpinner}
      />
    ) : null
  }

  getFormButton() {
    if (this.props.isRequest) {
      return (
        <div style={_.assign({}, styles.buttonWrapper, { pointerEvents: this.props.disableInputsAndSubmit ? 'none' : 'auto' })}>
          <AccessibleDivComponent style={_.assign({}, styles.submitButton, { padding: '18px 0' })} onClick={this.onSubmitClickHandler}>
            REQUEST
          </AccessibleDivComponent>
        </div>
      )
    }
    return (
      <div style={_.assign({}, styles.buttonWrapper, { pointerEvents: this.props.disableInputsAndSubmit ? 'none' : 'auto' })}>
        <AccessibleDivComponent style={styles.submitButton} onClick={this.onSubmitClickHandler}>
          Submit
        </AccessibleDivComponent>
      </div>
    )
  }

  isFreedomPayHpc() {
    return this.props.paymentType === AccountTypes.FREEDOMPAY && this.props.isFreedompayHpcEnabled
  }

  renderPaymentBlock() {
    const { fontFamily, fontsColorCheckoutInactive, fontsColorCheckoutActive, colorError } = this.props
    const textInputProps = {
      fontFamily,
      placeholderColor: fontsColorCheckoutInactive,
      textColor: fontsColorCheckoutActive,
      errorColor: colorError,
    }
    if (this.props.isPaymentRequired) {
      if (this.props.paymentType === AccountTypes.ADYEN) {
        return (
          <PaymentDiv id={this.checkoutPaymentId} isValid={this.state.adyen.state.isValid} submitClicked={this.state.submitClicked}>
            <AdyenGlobalStyleEvents />
            <AdyenForm
              venueId={this.props.venueId}
              infoForm={{ ...styles.infoForm, ...styles.adyenDropIn }}
              onSubmit={this.handlerOnSubmitAdyen}
              onValidate={this.handlerOnValidatedAdyen}
              setAdyenCheckout={this.handlerOnSetCheckout}
              onChangePaymentMethod={this.handlerOnAdyenChangePaymentMethod}
              dataFor3Dsecure={this.props.dataFor3Dsecure}
              onSuccess={this.props.postCheckoutSuccess}
              onFailure={this.props.postCheckoutFailure}
              amount={this.props.total}
            />
          </PaymentDiv>
        )
      } else if (this.props.paymentType === AccountTypes.CYBERSOURCE_3DS_REDUX && this.props.cybersourceThreeDsPaymentStep) {
        return <CheckoutCybersourceThreeDsPayment />
      } else if (this.props.paymentType === AccountTypes.SAFERPAY) {
        return (
          <>
            <SaferpayGlobalStyleDining />
            <SaferpayForm
              topElementId={this.checkoutPaymentId}
              venueId={this.props.venueId}
              onValidate={this.handlerOnValidatedSaferPay}
              dataFor3Dsecure={this.props.dataFor3Dsecure}
            />
          </>
        )
      } else if (this.isFreedomPayHpc()) {
        let fpComponent = ''
        if (this.props.freedompay.visible) {
          fpComponent = <CheckoutPaymentFreedompay topElementId={this.checkoutPaymentId} />
        }

        if (this.props.isFreedompayRequestOnlyPostalCode) {
          return fpComponent
        }
        return (
          <>
            <div style={styles.sectionWrapper}>
              <div style={styles.infoForm}>
                <GMapsAddress onAddressUpdate={this.onGMapsAddressUpdate} submitClicked={this.state.submitClicked} />
              </div>
            </div>
            {fpComponent}
          </>
        )
      } else if (this.props.paymentType === AccountTypes.SHIFT_4) {
        return <Shift4FormCheckout onSuccess={this.onShift4Success} />
      } else if (this.props.paymentType === AccountTypes.STRIPE) {
        return (
          <div style={styles.formWrapper} key={!!this.props.total}>
            <CheckoutPaymentStripe
              stripe={this.stripe || null}
              stripeIntentClientSecret={this.props.stripeIntentClientSecret}
              topElementId={this.checkoutPaymentId}
              paymentFields={this.inputFields.payment}
              textInputProps={textInputProps}
              submitClicked={this.state.submitClicked}
            />
          </div>
        )
      }
      return (
        <div style={styles.formWrapper} key={!!this.props.total}>
          <CheckoutPayment
            topElementId={this.checkoutPaymentId}
            paymentFields={this.inputFields.payment}
            textInputProps={textInputProps}
            submitClicked={this.state.submitClicked}
            isCybersourceBillingAddressRequired={this.props.isCybersourceBillingAddressRequired}
          />
        </div>
      )
    }
    return null
  }

  render() {
    const {
      enableRecaptcha,
      completeReCaptcha,
      recaptchaSiteKey,
      colorWidgetBackground,
      displayBookingPolicy,
      displaySpecialAttentionMessage,
      cancelPolicyText,
      toggleSpecialAttentionMessage,
      toggleBookingPolicyDisplay,
      displayVenueGroupMarketingOptInPolicy,
      venueGroupMarketingPolicy,
      toggleVenueGroupMarketingOptInPolicyDisplay,
      displayVenueSpecificMarketingOptInPolicy,
      venueSpecificMarketingPolicy,
      toggleVenueSpecificMarketingOptInPolicyDisplay,
      displayVenueSmsMarketingOptInPolicy,
      venueSmsMarketingPolicy,
      toggleVenueSmsMarketingOptInPolicyDisplay,
      displayReservationSmsOptInPolicy,
      displayTailoredCommunicationOptInPolicy,
      reservationSmsOptInPolicy,
      textTailoredCommunicationOptInBody,
      toggleReservationSmsOptInPolicyDisplay,
      toggleTailoredCommunicationOptInDisplay,
      termsPolicyHolderName,
      termsOfServiceUrl,
      termsOfServiceText,
      textCustomPolicyHolderName,
      textCustomPrivacyPolicyLink,
      textCustomPrivacyPolicyLinkLabel,
      textCustomGdprPolicyLink,
      textCustomGdprPolicyLinkLabel,
      showSpecialAttentionMessage,
      specialAttentionMessageHeader,
      specialAttentionMessageBody,
      colorPrimary,
      colorLines,
      fontsColorButton,
      fontFamily,
      fontsColorCheckoutInactive,
      fontsColorCheckoutActive,
      colorError,
      policyUsTwilioSmsOptInSubfooter,
      agreedToVenueSmsMarketingOptIn,
      agreedToReservationSmsOptIn,
    } = this.props

    const textInputProps = {
      fontFamily,
      placeholderColor: fontsColorCheckoutInactive,
      textColor: fontsColorCheckoutActive,
      errorColor: colorError,
    }

    const buildTermsAnchorTag = (href, text) => (
      <a style={styles.link} target="_blank" rel="noreferrer noopener" href={href} key={href + text}>
        {text}
      </a>
    )
    const groupTermsOfServiceAnchor = buildTermsAnchorTag(termsOfServiceUrl, termsOfServiceText)
    const gdprAnchor =
      !_.isEmpty(textCustomGdprPolicyLinkLabel) && buildTermsAnchorTag(textCustomGdprPolicyLink, textCustomGdprPolicyLinkLabel)
    const isSevenRoomsGdprPolicy = textCustomGdprPolicyLink.startsWith('https://www.sevenrooms.com/gdpr-policy/')
    const hasCustomPrivacyPolicy = !_.isEmpty(textCustomPrivacyPolicyLink)
    const customPrivacyPolicyAnchor =
      hasCustomPrivacyPolicy && buildTermsAnchorTag(textCustomPrivacyPolicyLink, textCustomPrivacyPolicyLinkLabel)
    return (
      <div style={styles.checkoutWrapper}>
        {this.getCheckoutSpinner()}
        {showSpecialAttentionMessage && (
          <div
            style={{
              backgroundColor: colorPrimary,
              fontColor: fontsColorButton,
              fontSize: '13px',
              borderBottomColor: colorLines,
              textAlign: 'center',
            }}
          >
            <div style={{ padding: '16px 0' }}>
              <span style={eventStyles.specialAttnLabel}>{specialAttentionMessageHeader}</span>
              <button
                type="button"
                style={{ color: fontsColorButton, ...eventStyles.infoIcon, ...eventStyles.buttonInfoIcon }}
                onClick={toggleSpecialAttentionMessage}
                className="fa fa-info-circle fa-fw"
                aria-hidden="true"
                aria-label="special attention message"
              />
            </div>
          </div>
        )}
        <div style={styles.formWrapper}>
          <CheckoutInformation
            topElementId={this.checkoutInfoId}
            infoFields={this.inputFields.information}
            textInputProps={textInputProps}
            disabled={this.props.disableInputsAndSubmit}
          />
        </div>
        {this.renderPaymentBlock()}
        <div style={styles.formWrapper}>
          <CheckoutSummary textInputProps={textInputProps} disabled={this.props.disableInputsAndSubmit} />
        </div>
        {enableRecaptcha ? (
          <div style={styles.summaryWrapper}>
            <ReCAPTCHA
              id="recaptcha-input"
              style={
                completeReCaptcha
                  ? {
                      ...styles.recaptcha,
                      borderColor: colorWidgetBackground,
                    }
                  : styles.invalidReCaptcha
              }
              // eslint-disable-next-line react/no-string-refs
              ref="recaptchaRef"
              sitekey={recaptchaSiteKey}
              onChange={this.onCaptchaChange}
            />
          </div>
        ) : null}
        <div style={_.assign({}, styles.footerText, { pointerEvents: this.props.disableInputsAndSubmit ? 'none' : 'auto' })}>
          {/* See WB-1546 for terms/policy formatting/language */}
          {`By clicking below you agree to ${termsPolicyHolderName} `}
          {/* eslint-disable-next-line no-nested-ternary */}
          {hasCustomPrivacyPolicy && isSevenRoomsGdprPolicy
            ? [
                groupTermsOfServiceAnchor,
                gdprAnchor && ' and ',
                gdprAnchor,
                ` and ${textCustomPolicyHolderName} `,
                customPrivacyPolicyAnchor,
              ]
            : hasCustomPrivacyPolicy
            ? [
                groupTermsOfServiceAnchor,
                ` and ${textCustomPolicyHolderName} `,
                customPrivacyPolicyAnchor,
                gdprAnchor && ' and ',
                gdprAnchor,
              ]
            : [groupTermsOfServiceAnchor, gdprAnchor && ' and ', gdprAnchor]}
        </div>
        {(agreedToVenueSmsMarketingOptIn || agreedToReservationSmsOptIn) && (
          <div style={{ ...styles.footerText, marginBottom: '14px' }}>
            <IntlProvider locale="en">
              <FormattedMessage
                id="policyUsTwilioSmsOptInSubfooter"
                // eslint-disable-next-line formatjs/enforce-default-message
                defaultMessage={policyUsTwilioSmsOptInSubfooter}
                values={{
                  i: chunks => <i>{chunks}</i>,
                }}
              />
            </IntlProvider>
          </div>
        )}
        {this.getFormButton()}
        {displaySpecialAttentionMessage ? (
          <PolicyPopover
            policyHeader={specialAttentionMessageHeader}
            policyDetails={specialAttentionMessageBody}
            togglePopover={toggleSpecialAttentionMessage}
          />
        ) : null}
        {displayBookingPolicy ? (
          <PolicyPopover policyHeader="Cancellation Policy" policyDetails={cancelPolicyText} togglePopover={toggleBookingPolicyDisplay} />
        ) : null}
        {displayVenueGroupMarketingOptInPolicy ? (
          <PolicyPopover
            policyHeader="Marketing Policy"
            policyDetails={venueGroupMarketingPolicy}
            togglePopover={toggleVenueGroupMarketingOptInPolicyDisplay}
          />
        ) : null}
        {displayVenueSpecificMarketingOptInPolicy ? (
          <PolicyPopover
            policyHeader="Marketing Policy"
            policyDetails={venueSpecificMarketingPolicy}
            togglePopover={toggleVenueSpecificMarketingOptInPolicyDisplay}
          />
        ) : null}
        {displayVenueSmsMarketingOptInPolicy ? (
          <PolicyPopover
            policyHeader="Marketing Policy"
            policyDetails={venueSmsMarketingPolicy}
            togglePopover={toggleVenueSmsMarketingOptInPolicyDisplay}
          />
        ) : null}
        {displayReservationSmsOptInPolicy ? (
          <PolicyPopover
            policyHeader="Reservation SMS Policy"
            policyDetails={reservationSmsOptInPolicy}
            togglePopover={toggleReservationSmsOptInPolicyDisplay}
          />
        ) : null}
        {displayTailoredCommunicationOptInPolicy ? (
          <PolicyPopover
            policyHeader="Marketing Policy"
            policyDetails={textTailoredCommunicationOptInBody}
            togglePopover={toggleTailoredCommunicationOptInDisplay}
          />
        ) : null}
        {this.props.displayPromoCodeError ? (
          <PolicyPopover
            policyHeader="Error"
            policyDetails={this.props.promoCodeErrorMsg}
            togglePopover={this.props.togglePromoCodeErrorDisplay}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { eventId } = state.userSelection.toJS()
  const cart = getSelectedInventoryCart(state)
  const selectedEvent = state.entities.events.toJS()[eventId]

  const { total } = calculateSelectedInventoryPrices(state)
  const isPaymentRequired = total && total > 0
  const isRequest = cart.some(({ inventoryType }) => inventoryType === REQUEST_TYPE)
  const isGratuityEnabled = cart.some(({ inventoryId }) => {
    const inventory = state.entities.inventory.get(inventoryId)
    return inventory.get('chargeGratuity')
  })
  const shouldRequireGratuity = cart.some(({ inventoryId }) => {
    const inventory = state.entities.inventory.get(inventoryId)
    return inventory.get('requireGratuityCharge')
  })
  const shouldEnableCustomGratuity = cart.some(({ inventoryId }) => {
    const inventory = state.entities.inventory.get(inventoryId)
    return inventory.get('gratuityAmountType') === 'CUSTOMER_VARIABLE' && inventory.get('chargeGratuity')
  })

  const venueGroupMarketingPolicy = CheckoutPolicySelector.selectVenueGroupMarketingPolicy(state)
  const venueSpecificMarketingPolicy = CheckoutPolicySelector.selectVenueSpecificMarketingPolicy(state)
  const venueSmsMarketingPolicy = CheckoutPolicySelector.selectVenueSmsMarketingPolicy(state)
  const reservationSmsOptInPolicy = CheckoutPolicySelector.selectReservationSmsOptInPolicy(state)
  const tailoredCommunicationOptInPolicy = CheckoutPolicySelector.selectTailoredCommunicationOptInPolicy(state)
  const cancelPolicyText = CheckoutPolicySelector.selectBookingPolicy(state)
  const languageStrings = selectLanguageStrings(state)

  return {
    chargeGratuity: isGratuityEnabled,
    requireGratuityCharge: shouldRequireGratuity,
    shouldEnableCustomGratuity,
    customTip: state.formFields.get('customTip') || 0,
    total,
    eventName: selectedEvent.name,
    isRequest,
    disableInputsAndSubmit: state.formFields.get('disableInputsAndSubmit'),
    formFields: state.formFields.toJS(),
    displayBookingPolicy: state.formFields.get('displayBookingPolicy'),
    displaySpecialAttentionMessage: state.formFields.get('displaySpecialAttentionMessage'),
    displayAboveAgeConsentPolicy: state.formFields.get('displayAboveAgeConsentPolicy'),
    displayVenueGroupMarketingOptInPolicy: state.formFields.get('displayVenueGroupMarketingOptInPolicy'),
    displayVenueSpecificMarketingOptInPolicy: state.formFields.get('displayVenueSpecificMarketingOptInPolicy'),
    displayVenueSmsMarketingOptInPolicy: state.formFields.get('displayVenueSmsMarketingOptInPolicy'),
    displayReservationSmsOptInPolicy: state.formFields.get('displayReservationSmsOptInPolicy'),
    displayTailoredCommunicationOptInPolicy: state.formFields.get('displayTailoredCommunicationOptInPolicy'),
    tailoredCommunicationOptInPolicy,
    displayPromoCodeError: state.formFields.get('displayPromoCodeError'),
    agreedToBookingPolicy: state.formFields.get('agreedToBookingPolicy'),
    agreedToAboveAgeConsentOn: state.formFields.get('agreedToAboveAgeConsentOn'),
    recaptcha: state.formFields.get('recaptcha'),
    birthday: state.formFields.get('birthday'),
    birthdayType: state.widgetSettings.birthdayType,
    salutationType: state.widgetSettings.salutationType,
    isPaymentRequired,
    enableRecaptcha: state.formFields.get('recaptchaOn'),
    completeReCaptcha: state.formFields.get('completeReCaptcha'),
    reCaptchaFieldStatus: state.formFields.get('reCaptchaFieldStatus'),
    recaptchaSiteKey: state.widgetSettings.recaptchaSiteKey,
    termsPolicyHolderName: state.widgetSettings.termsPolicyHolderName,
    termsOfServiceUrl: state.widgetSettings.termsOfServiceUrl,
    termsOfServiceText: state.widgetSettings.termsOfServiceText,
    textCustomPolicyHolderName: state.widgetSettings.textCustomPolicyHolderName,
    textCustomPrivacyPolicyLink: state.widgetSettings.textCustomPrivacyPolicyLink,
    textCustomPrivacyPolicyLinkLabel: state.widgetSettings.textCustomPrivacyPolicyLinkLabel,
    textCustomGdprPolicyLink: state.widgetSettings.textCustomGdprPolicyLink,
    textCustomGdprPolicyLinkLabel: state.widgetSettings.textCustomGdprPolicyLinkLabel,
    loginSite: state.user.get('site'),
    mediaUrl: state.widgetSettings.mediaUrl,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    venueId: state.venueInfo.id,
    accountId: state.venueInfo.accountId,
    widgetSettings: state.widgetSettings,
    venueGroupMarketingPolicy,
    venueSpecificMarketingPolicy,
    venueSmsMarketingPolicy,
    reservationSmsOptInPolicy,
    cancelPolicyText,
    promoCodeErrorMsg: state.formFields.get('promoCodeErrorMsg'),
    showSpecialAttentionMessage: parseBoolean(state.widgetSettings.showSpecialAttentionMessage),
    specialAttentionMessageHeader: languageStrings.textEventWidgetSpecialAttentionLabel,
    specialAttentionMessageBody: languageStrings.textEventWidgetSpecialAttentionInfoBody,
    colorPrimary: state.widgetSettings.colorPrimary,
    colorLines: state.widgetSettings.colorLines,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    fontsColorCheckoutInactive: state.widgetSettings.fontsColorCheckoutInactive,
    fontsColorCheckoutActive: state.widgetSettings.fontsColorCheckoutActive,
    colorError: state.widgetSettings.fontsColorError,
    fontFamily: state.widgetSettings.font,
    freedompay: state.payment.freedompay,
    isFreedompayHpcEnabled: state.venueInfo.isFreedompayHpcEnabled,
    isFreedompayRequestOnlyPostalCode: state.venueInfo.isFreedompayRequestOnlyPostalCode,
    paymentType: state.venueInfo.paymentType,
    cybersourceThreeDsPaymentStep: state.payment.cybersourceThreeds.step,
    isCybersourceBillingAddressRequired: state.venueInfo.isCybersourceBillingAddressRequired,
    dataFor3Dsecure: state.commonPayment.dataFor3Dsecure,
    addressCountryCode: state.formFields.get('countryCode'),
    policyUsTwilioSmsOptInSubfooter: languageStrings.policyUsTwilioSmsOptInSubfooter,
    paymentEngine: state.commonPayment.paymentEngine,
    currency: state.venueInfo.currencyCode,
    venueCountryCode: state.venueInfo.municipality.countryCode,
    locale: state.venueInfo.selectedLanguageCode,
    stripeObject: state.commonPayment.stripe,
    stripeIntentClientSecret: state.commonPayment.stripeIntentClientSecret,
    agreedToVenueSmsMarketingOptIn: state.formFields.get('agreedToVenueSmsMarketingOptIn'),
    agreedToReservationSmsOptIn: state.formFields.get('agreedToReservationSmsOptIn'),
    textTailoredCommunicationOptInLabel: languageStrings.textTailoredCommunicationOptInLabel,
    textTailoredCommunicationOptInHeader: languageStrings.textTailoredCommunicationOptInHeader,
    textTailoredCommunicationOptInBody: languageStrings.textTailoredCommunicationOptInBody,
    featureFlags: state.featureFlags,
  }
}

const mapDispatchToProps = dispatch => ({
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
  submitCheckoutData: (data, isRequest, hasCharge, stripe) => {
    if (isRequest) {
      dispatch(submitRequest(data))
    } else if (!hasCharge) {
      dispatch(submitCheckoutDataNoPayment(data))
    } else {
      dispatch(submitCheckoutData(data, stripe))
    }
  },
  changeCaptchaField: recaptchaValue => {
    dispatch(changeCaptchaField(recaptchaValue))
  },
  completeCaptchaField: recaptchaValue => {
    dispatch(completeCaptchaField(recaptchaValue))
  },
  toggleSpecialAttentionMessage: () => {
    dispatch(toggleSpecialAttentionMessage())
  },
  toggleBookingPolicyDisplay: () => {
    dispatch(toggleBookingPolicyDisplay())
  },
  toggleVenueGroupMarketingOptInPolicyDisplay: () => {
    dispatch(toggleVenueGroupMarketingOptInPolicyDisplay())
  },
  toggleVenueSpecificMarketingOptInPolicyDisplay: () => {
    dispatch(toggleVenueSpecificMarketingOptInPolicyDisplay())
  },
  toggleVenueSmsMarketingOptInPolicyDisplay: () => {
    dispatch(toggleVenueSmsMarketingOptInPolicyDisplay())
  },
  toggleReservationSmsOptInPolicyDisplay: () => {
    dispatch(toggleReservationSmsOptInPolicyDisplay())
  },
  toggleTailoredCommunicationOptInDisplay: () => {
    dispatch(toggleTailoredCommunicationOptInDisplay())
  },
  togglePromoCodeErrorDisplay: () => {
    dispatch(togglePromoCodeErrorDisplay())
  },
  validateAllFields: formErrors => {
    dispatch(validateAllFields(formErrors))
  },
  mandatePolicies: formErrors => {
    dispatch(mandatePolicies(formErrors))
  },
  dismissSpinner: () => {
    dispatch(dismissSpinner())
  },
  tryPostCheckout: () => {
    dispatch(tryPostCheckout())
  },
  postCheckoutFailure: errorMessage => {
    dispatch(postCheckoutFailure(errorMessage))
  },
  postCheckoutSuccess: () => dispatch(postCheckoutSuccess()),
  initErrorScrollAnimation: targetId => {
    dispatch(initErrorScrollAnimation(targetId))
  },
  clearErrorScrolling: () => {
    dispatch(clearErrorScrolling())
  },
  toggleCheckoutErrorModal: response => {
    dispatch(toggleCheckoutErrorModal(response))
  },
  checkPromoCodeForCheckout: () => {
    dispatch(checkPromoCodeForCheckout())
  },
  setFreedompayVisible: visible => {
    dispatch(setFreedompayVisible(visible))
  },
  submitPaymentCheckoutDataCybersourceThreeDSRedux: () => {
    dispatch(submitPaymentCheckoutDataCybersourceThreeDSRedux())
  },
  createStripeIntent: () => {
    dispatch(createStripeIntent())
  },
})

const PaymentDiv = styled.div`
  width: 89.33333%;
  margin: 0 auto;
  border-radius: 8px;
  border-width: 1px;
  border-color: red;
  font-size: 14px;
  border-style: ${props => (!props.isValid && props.submitClicked ? 'solid' : 'hidden')};
`

const AdyenGlobalStyleEvents = createGlobalStyle`
  #sr-checkout-payment .adyen-checkout__label__text {
      padding: 10px 0px 10px 10px ;
      font-size: 14px;
  }
  .adyen-checkout__input-wrapper {
      margin: 0px 13px 15px 10px;
  }
  .adyen-checkout__field {
      margin-bottom: 0;
  }
  .adyen-checkout__issuer-list {
      padding: 1px;
  }
`

const SaferpayGlobalStyleDining = createGlobalStyle`
  #sr-checkout-payment {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 39%) 0px 0px 12px -2px;
    margin: 10px 40px;
  }
  #fields-holder-name, #fields-card-number, #fields-expiration, #fields-cvc {
      height: 40px;
      margin-bottom: 5px;
  }
`

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
